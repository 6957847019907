import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Table,
  Button,
  Typography,
  Divider,
  Empty,
  Col,
  Row,
  message
} from "antd";
import { isMobile } from "react-device-detect";
import BottomFooter from "../Footer/BottomFooter";
import { LeftCircleOutlined } from "@ant-design/icons";
import potaliClosed from "../../assets/mobile/potali/close.png";
import potaliOpen from "../../assets/mobile/potali/open.png";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";

const { Title } = Typography;

export default function ToInviteList() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const user = useSelector((state) => state.userReducer);
  let navigate = useNavigate();
  const userId = user._id;
  const address = useSelector((state) => state.locationReducer);

  const [invitationsList, setInvitationsList] = useState([]);
  const [restList, setRestList] = useState([]);

  const lng = address.longitude;
  const lat = address.latitude;

  const fetchInvitations = async () => {
    const payload = { appUserId: userId };

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/invitationsList`,
        payload
      );
      const invitedData = response.data.data;
      setInvitationsList(invitedData);
    } catch (error) {
      console.error("Error fetching invitations list:", error);
    }
  };

  useEffect(() => {
    fetchInvitations();
  }, [userId]);
  useEffect(() => {
    const key = location.state?.key || "food_and_drink";
    // const key = location.state?.key || "restaurant";
    console.log("Fetching data for key:", key);

    const fetchData = async () => {
      const apiUrl = `https://api.mapbox.com/search/v1/category/${key}?language=en&limit=100&proximity=${lng},${lat}&access_token=sk.eyJ1Ijoib20tZGF0YSIsImEiOiJjbG5tc3V0M3AwMzRhMmltcXFrdnp1dGpvIn0.gKxjTW2AVKe9P85bP8xaeQ`;

      try {
        const response = await axios.get(apiUrl);
        let resData = response.data.features;
        setRestList(resData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.state?.key, lng, lat]);

  const isInvited = (name) => {
    if (invitationsList) {
      for (let i = 0; i < invitationsList.length; i++) {
        if (invitationsList[i].name === name) {
          return true;
        }
      }
      return false;
    }
  };

  const inviteFxn = async (name, address, cityName) => {
    const key = location.state?.key;
    const payload = {
      type: key === "salon" ? "salon" : "restaurant",
      name: name,
      appUserId: userId,
      latitude: lng,
      longitude: lat,
      cityName: cityName,
      address: address
    };

    try {
      const response = await axios.post(
        `${apiUrl}${tDriveEndpoint}appuser/invitations`,
        payload
      );

      if (response.data.status === 200) {
        message.success("Invitasjon sendt!");
        fetchInvitations();
        setRestList((prevList) =>
          prevList.map((item) =>
            item.name === name ? { ...item, isInvited: true } : item
          )
        );
      } else if (response.data.status === 403) {
        message.error("Allerede invitert" || response.data.message);
      }
    } catch (error) {
      console.error("Error sending invitation:", error);
      message.error("Kunne ikke sende invitasjon. Vennligst prøv igjen.");
    }
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  const columns = [
    {
      title: "S.No",
      dataIndex: "index",
      key: "index",
      width: 50,
      responsive: ["lg"],
      render: (_, __, index) => index + 1
    },
    {
      title: "Navn",
      dataIndex: "name",
      key: "name",
      width: 100,
      render: (text, record) => <b>{text}</b>
    },
    {
      title: "",
      dataIndex: "image",
      key: "image",
      width: 50,
      render: (_, record) => (
        <>
          {record.isInvited ? (
            <img
              src={potaliClosed}
              loading="lazy"
              alt={record.isInvited ? "Invitert" : "Inviter"}
            />
          ) : (
            <img
              src={potaliOpen}
              loading="lazy"
              alt={record.isInvited ? "Invitert" : "Inviter"}
            />
          )}
        </>
      )
    },
    {
      title: "Handling",
      key: "action",
      align: "center", // Center-align the content
      fixed: "right",
      width: 60,
      render: (_, record) => (
        <Button
          type="primary"
          className="border-radius-50"
          disabled={record.isInvited} // Disables the button if already invited
          onClick={() => inviteFxn(record.name, record.address, record.city)}
        >
          {record.isInvited ? "Invitert" : "Inviter"} {/* Dynamic label */}
        </Button>
      )
    }
  ];

  const data = restList.map((data, index) => {
    const isRestaurantInvited = isInvited(data.properties.feature_name);
    const place = data.properties.context
      ? data.properties.context.find((item) => item.localized_layer === "place")
      : null;
    const placeName = place ? place.name : null;

    return {
      key: index,
      name: data.properties.feature_name,
      address: data.properties.place_name,
      city: placeName,
      isInvited: isRestaurantInvited
    };
  });

  const tableContent = () => {
    return (
      <>
        <Row className="justify-content-between">
          {" "}
          <Title level={3} className="mb-3">
            <h3 className="font-24 font-700 mb-0 mt-3">
              {location.state?.key === "salon"
                ? "Salong List"
                : "Restaurantliste"}
            </h3>
          </Title>
          <LeftCircleOutlined
            onClick={() => window.history.back()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "30px"
            }}
          />
        </Row>
        <Divider>
          I nærheten {location.state?.key === "salon" ? "Salong" : "Restaurant"}
        </Divider>
        <Table
          className="mt-3 border-radius-10"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: "0", y: 620 }} // Enable vertical and horizontal scrolling
          locale={{
            emptyText: <Empty description="Ingen data funnet" />
          }}
        />
      </>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="vh90andScroll mb-5 pb-5">
          {loading ? (
            <>................</>
          ) : (
            <>
              <div className="px-3" style={{ marginTop: "70px" }}>
                {tableContent()}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {loading ? (
            <>................</>
          ) : (
            <div
              style={{ marginTop: "100px" }}
              className="mission profilePages"
            >
              <Row className="align-items-center justify-content-center">
                <Col lg={12} className="commonShadowAndRound p-5">
                                  {tableContent()}
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <BottomFooter />
    </>
  );
}
