import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Tabs } from "antd";
import { useSelector } from "react-redux";
import BottomFooter from "../Footer/BottomFooter";
import { isMobile } from "react-device-detect";
const DigitalServices = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState({});
  const user = useSelector((state) => state.userReducer);

  const truncate = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  const ServiceCard = ({ title, description, image }) => (
    <div className="service-card">
      <Row className="justify-content-between">
        <Col
          span="8"
          className="d-flex justify-content-center align-items-center"
        >
          <img src={image} alt={title} className="service-image width-100" />
        </Col>
        <Col
          span="14"
          className="p-2 d-flex flex-column justify-content-center align-items-start"
        >
          <h2>{title}</h2>
          <p>{description}</p>
          <Button className="mainBgColor border-radius-5 width-100 text-white">
            Kontakt oss
          </Button>
        </Col>
      </Row>
    </div>
  );
  const ServiceCardWeb = ({ title, description, image }) => (
    <div className="service-card">
      <img src={image} alt={title} loading="lazy" className="service-image" />
      <h2>{title}</h2>
      <p>{truncate(description, 100)}</p>
      <Button className="mainBgColor border-radius-5 text-white">
        Kontakt oss
      </Button>
    </div>
  );

  const services = [
    {
      title: "Webdesign",
      description:
        "Lag fantastiske, brukervennlige nettsteder som hever din online tilstedeværelse.",
      image: require("../../assets/mobile/SERVICE_icon/Web Designing.webp")
    },
    {
      title: "Design av logo",
      description:
        "Lag en unik logo som definerer din merkevareidentitet og visjon.",
      image: require("../../assets/mobile/SERVICE_icon/Logo Designing.webp")
    },
    {
      title: "Apper",
      description:
        "Strømlinjeform transaksjonene dine med tilpassede PoS-apper som er skreddersydd for bedriftens behov.",
      image: require("../../assets/mobile/SERVICE_icon/PoS Apps for Business.webp")
    },
    {
      title: "Digital markedsføring",
      description:
        "Øk rekkevidden og engasjementet med skreddersydde digitale markedsføringsstrategier.",
      image: require("../../assets/mobile/SERVICE_icon/Digital Marketing.webp")
    },
    {
      title: "Sosiale medier administrasjon og innlegg",
      description:
        "La oss håndtere dine sosiale medier for å holde publikumet ditt engasjert og vokse.",
      image: require("../../assets/mobile/SERVICE_icon/Social Media Management and Postings.webp")
    },
    {
      title: "Bedriftskonsultasjon",
      description:
        "Ekspertråd for å optimalisere din digitale virksomhet for suksess.",
      image: require("../../assets/mobile/SERVICE_icon/Consultation for Your Digital Business.webp")
    },
    {
      title: "Menydesign",
      description:
        "Imponer kundene dine med profesjonelt utformede, lettleste menyer.",
      image: require("../../assets/mobile/SERVICE_icon/Menu Designing.webp")
    }
  ];
  const servicesBusiness = [
    {
      title: "Prosjektledelse",
      description:
        "Administrer prosjektene dine effektivt med våre ekspertløsninger for prosjektstyring.",
      image: require("../../assets/mobile/SERVICE_icon/1Project Management.webp")
    },
    {
      title: "Apper",
      description:
        "Strømlinjeform transaksjonene dine med tilpassede PoS-apper som er skreddersydd for bedriftens behov.",
      image: require("../../assets/mobile/SERVICE_icon/PoS Apps for Business.webp")
    },
    {
      title: "Menydesign",
      description:
        "Imponer kundene dine med profesjonelt utformede, lettleste menyer.",
      image: require("../../assets/mobile/SERVICE_icon/Menu Designing.webp")
    },
    {
      title: "Webdesign",
      description:
        "Lag fantastiske, brukervennlige nettsteder som hever din online tilstedeværelse.",
      image: require("../../assets/mobile/SERVICE_icon/Web Designing.webp")
    },
    {
      title: "Design av logo",
      description:
        "Lag en unik logo som definerer din merkevareidentitet og visjon.",
      image: require("../../assets/mobile/SERVICE_icon/Logo Designing.webp")
    },
    {
      title: "Plakatdesign",
      description:
        "Iøynefallende plakater som fanger oppmerksomhet og promoterer virksomheten din effektivt.",
      image: require("../../assets/mobile/SERVICE_icon/Poster Designing.webp")
    },
    {
      title: "Digital markedsføring",
      description:
        "Øk rekkevidden og engasjementet med skreddersydde digitale markedsføringsstrategier.",
      image: require("../../assets/mobile/SERVICE_icon/Digital Marketing.webp")
    },
    {
      title: "Sosiale medier administrasjon og innlegg",
      description:
        "La oss håndtere dine sosiale medier for å holde publikumet ditt engasjert og vokse.",
      image: require("../../assets/mobile/SERVICE_icon/Social Media Management and Postings.webp")
    },
    {
      title: "Bedriftskonsultasjon",
      description:
        "Ekspertråd for å optimalisere din digitale virksomhet for suksess.",
      image: require("../../assets/mobile/SERVICE_icon/Consultation for Your Digital Business.webp")
    },
    {
      title: "Utvikling av mobilapper",
      description:
        "Bygg funksjonsrike mobilapper for å forbedre virksomheten din mens du er på farten.",
      image: require("../../assets/mobile/SERVICE_icon/Mobile App Development.webp")
    }
  ];

  return (
    <>
      {isMobile && (
        <Tabs
          className="p-3 digitalServicePage"
          id="tflMarketRow"
          defaultActiveKey="item-1"
        >
          <Tabs.TabPane tab="For enkeltpersoner" key="item-1">
            {services.map((service, index) => (
              <Card
                className="mb-3 border-radius-5 spreadedShadow"
                onClick={() => {
                  navigate("/queryForm", {
                    state: { serviceTitle: service.title, for: "individual" }
                  });
                }}
              >
                {" "}
                <ServiceCard
                  key={index}
                  title={service.title}
                  description={service.description}
                  image={service.image}
                />
              </Card>
            ))}
          </Tabs.TabPane>
          <Tabs.TabPane tab="For Business" key="item-2">
            {servicesBusiness.map((service, index) => (
              <Card
                className="mb-3 border-radius-5 spreadedShadow"
                onClick={() => {
                  navigate("/queryForm", {
                    state: { serviceTitle: service.title, for: "Business" }
                  });
                }}
              >
                {" "}
                <ServiceCard
                  key={index}
                  title={service.title}
                  description={service.description}
                  image={service.image}
                />
              </Card>
            ))}
          </Tabs.TabPane>
        </Tabs>
      )}
      {!isMobile && (
        <div style={{ marginTop: "120px" }}>
          <Tabs
            className="p-3 digitalServicePage ServiceCardWeb px-3"
            id="tflMarketRow"
            defaultActiveKey="item-1"
          >
            <Tabs.TabPane tab="For enkeltpersoner" key="item-1">
              <Row className="justify-content-between">
                {services.map((service, index) => (
                  <Col span={6} className="p-2">
                    <Card
                      className="border-radius-5 spreadedShadow"
                      // onClick={() =>
                      //   window.open("https://ezygo.no/individual-form")
                      // }
                      onClick={() => {
                        navigate("/queryForm", {
                          state: {
                            serviceTitle: service.title,
                            for: "individual"
                          }
                        });
                      }}
                    >
                      {" "}
                      <ServiceCardWeb
                        key={index}
                        title={service.title}
                        description={service.description}
                        image={service.image}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="For Business" key="item-2">
              <Row className="justify-content-between">
                {servicesBusiness.map((service, index) => (
                  <Col span={6} className="p-2">
                    <Card
                      className=" border-radius-5 spreadedShadow"
                      // onClick={() =>
                      //   window.open("https://ezygo.no/business-form")
                      // }
                      onClick={() => {
                        navigate("/queryForm", {
                          state: {
                            serviceTitle: service.title,
                            for: "Business"
                          }
                        });
                      }}
                    >
                      {" "}
                      <ServiceCardWeb
                        key={index}
                        title={service.title}
                        description={service.description}
                        image={service.image}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
      <BottomFooter />
    </>
  );
};

export default DigitalServices;
