import React, { useState, useEffect } from "react";
import BottomFooter from "../Footer/BottomFooter";
import { Card, Row, Col, Button, Avatar, Divider } from "antd";
import { isMobile } from "react-device-detect";

export default function AgentProfile() {
  const targetDate = new Date("2025-06-26T00:00:00Z").getTime();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const content = () => {
    return (
      <Col
        xs={22}
        md={6}
        style={{ background: "rgba(91, 144, 206, 0.7) " }}
        className="border-radius-10 p-4"
      >
        <h3 className="text-white text-center font-35 py-4">
          {timeLeft.days} Days <br />
          {timeLeft.hours}h {timeLeft.minutes}m{timeLeft.seconds}s
        </h3>
      </Col>
    );
  };
  return (
    <>
      {/* <Row className="agentPage align-items-center justify-content-center p-4">
        <Col
          className="agentPageBox width-100 text-center border-radius-10"
          
        ></Col>
      </Row> */}
      {isMobile ? (
        <Row className="agentPage align-items-center justify-content-center">
          {" "}
          {content()}
        </Row>
      ) : (
        <div style={{ marginTop: "70px" }}>
          <Row className="align-items-center justify-content-center agentPage align-items-center justify-content-center">
            {content()}
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
