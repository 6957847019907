import { createSlice } from "@reduxjs/toolkit";
import { revertCus } from "../actions/rootaction";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

let initialState = {
  _id: "",
  email: "",
  phone: "",
  lastName: "",
  firstName: "",
  gender: "",
  dateOfBirth: "",
  countryCode: "",
  userReferralCode: "",
  token: "",
  profileImage: ""
};

let userSlice = createSlice({
  name: "User",
  initialState,
  extraReducers: (builder) => {
    return builder.addCase(revertCus, () => {
      storage.removeItem("persist:root");

      return initialState;
    });
  },
  reducers: {
    saveUser: (state, action) => {
      console.log("saveUser payload:", action.payload);
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.lastName = action.payload.lastName;
      state.firstName = action.payload.firstName;
      state.gender = action.payload.gender;
      state.dateOfBirth = action.payload.dateOfBirth;
      state.countryCode = action.payload.countryCode;
      state._id = action.payload.id;
      state.userReferralCode = action.payload.userReferralCode;
      state.token = action.payload.token;
      state.profileImage = action.payload.profileImage;
    },
    removeUser: (state, action) => {
      return initialState;
    }
  }
});

export const userAction = userSlice.actions;
export default userSlice.reducer;