import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import MobileNavTop from "../Header/MobileTopNav";
import BottomFooter from "../Footer/BottomFooter";
import { s3url, apiUrl } from "../../helper/endpoint";

import { Container } from "react-bootstrap";

import { Row, Col, Layout, Carousel, Card } from "antd";
import { sendLocationAction } from "../../store/actions/locationaction";

import ScrollToTop from "../ScrollToTop";

import { rootAction } from "../../store/slices/rootSlices";

import img11 from "../../assets/webBanner/Salon.webp";
import img22 from "../../assets/webBanner/restaurant.webp";
import img33 from "../../assets/webBanner/e-service.webp";
import img44 from "../../assets/webBanner/E-Mart.webp";
import img1 from "../../assets/mobile/img1.webp";
import img2 from "../../assets/mobile/img2.webp";
import img3 from "../../assets/mobile/img3.webp";
import img4 from "../../assets/mobile/img4.webp";
import doll from "../../assets/ezygo.shop.webp";
import coins1 from "../../assets/mobile/coins/coin1100by100.webp";
import coins2 from "../../assets/mobile/coins/coin2100by100.webp";
import coins3 from "../../assets/mobile/coins/coin3100by100.webp";
import coins4 from "../../assets/mobile/coins/coin4100by100.webp";
import coins5 from "../../assets/mobile/coins/coin5100by100.webp";
import { customerAction } from "../../store/slices/customerslices";

const { Header, Footer, Content } = Layout;

function App() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { Meta } = Card;
  const carouselRef = useRef(null);
  let user = useSelector((state) => state.userReducer);
  const location = useSelector((state) => state.locationReducer);
  useEffect(() => {
    dispatch(sendLocationAction());
  }, [dispatch]);
  const [giftCardModal, setgiftCardModal] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const renderAd = (data) => {
    let adType = data.contentType == "video" ? "video" : "picture";
    let url = `${s3url}/${data.url}`;

    let ele = (
      <img
        src={url}
        alt={adType}
        height={240}
        className=" border-radius-10"
        loading="lazy"
      />
    );

    if (adType == "video") {
      ele = (
        <video
          controls
          width="100%"
          height="200"
          onEnded={handleVideoEnd}
          className=" border-radius-10"
        >
          <source src={url} type="video/mp4" />
          Nettleseren din støtter ikke videokoden!
        </video>
      );
    }
    return ele;
  };
  const handleVideoEnd = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const getHomePageAdv = async () => {
    // console.log("---fetching advertisments for home page---");
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/treeDrive/restaurant/advertisementsNew`
      );

      let homePageAds = response.data.data;
      setAdvertisements(homePageAds);
      // console.log("--homepage advertisements---", homePageAds);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    dispatch(customerAction.removeCustomer());
    localStorage.removeItem("TFLCartData");
    localStorage.removeItem("resName");
    localStorage.removeItem("resId");
    localStorage.removeItem("resLoc");
    localStorage.removeItem("restaurantWebsite");
    if (isMobile) {
      getHomePageAdv();
    }
  }, []);

  const handleClick = (modalId) => {
    // console.log("modalId", modalId);
    navigate(`/modal/${modalId}`);
  };

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const BannerItem = ({ onClick, src, alt }) => (
    <a onClick={onClick} style={{ textDecoration: "none" }}>
      <img src={src} alt={alt} width={"100%"} loading="lazy" />
    </a>
  );
  return (
    <Layout>
      {!isMobile && (
        <>
          {" "}
          <Row className="bannerImg">
            {/* Left Column */}
            <Col span={6} className="d-flex justify-content-center">
              <Col span={12} className="d-flex flex-column">
                <BannerItem
                  onClick={() => navigate("/salonslist")}
                  src={img11}
                  alt="Salong"
                />
                <BannerItem
                  onClick={() => navigate("/digitalServices")}
                  src={img33}
                  alt="E-tjeneste"
                />
              </Col>
              <Col span={12} className="d-flex flex-column">
                <BannerItem
                  onClick={() => {
                    dispatch(rootAction.setOrderType("Drive Out"));
                    navigate("/restaurants");
                  }}
                  src={img22}
                  alt="Bestill mat"
                />
                <BannerItem
                  onClick={() => navigate("/tflMarket")}
                  src={img44}
                  alt="E-marked"
                />
              </Col>
            </Col>

            {/* Right Column */}
            <Col span={11}>
              <img src={doll} alt="Banner" width="100%" />
            </Col>
          </Row>
        </>
      )}
      {isMobile && <MobileNavTop />}
      {isMobile && (
        <Container
          fluid
          id="online-header"
          className="px-0 py-5 my-2 mobileView"
        >
          <Row justify="center" align="middle" className="px-3 pt-3">
            <Col span={24}>
              {user.token !== "" && (
                <p className="pl-1 pt-1 mb-0">
                  <>
                    Hallo{" "}
                    <b>
                      {capitalizeFirstLetter(user.firstName)}{" "}
                      {capitalizeFirstLetter(user.lastName)}!👋
                    </b>
                  </>
                </p>
              )}
              <h3 className="welcome-text mb-0 sansita-black">
                Velkommen til EzyGo!
              </h3>
              <p className="pl-1 pt-1">
                {user.token === "" && (
                  <>
                    For flere fordeler{" "}
                    <NavLink to="/signup">
                      <i>
                        <u>
                          <b>Registrer</b>
                        </u>
                      </i>
                    </NavLink>{" "}
                    deg eller{" "}
                    <NavLink to="/login">
                      <i>
                        <u>
                          <b>Logg inn</b>{" "}
                        </u>
                      </i>
                    </NavLink>{" "}
                    !!
                  </>
                )}
              </p>
            </Col>
          </Row>

          <div className="px-3 mb-2">
            <Carousel
              id="topSlider"
              autoplay
              className="border-radius-10"
              style={{
                backgroundImage: `url(${require("../../assets/mobile/coins/bg.webp")})`,
                backgroundSize: "cover"
              }}
            >
              <div
                key="0"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(1)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  Tjen og løs inn TFL-mynter!
                </h3>
                <img
                  src={coins3}
                  alt="TFL Coins"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="1"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(2)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  Maksimer fordelene med EzyGo AI!
                </h3>
                <img
                  src={coins4}
                  alt="EzyGo AI"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="2"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(3)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  Fyll posen din med TFL-mynter!
                </h3>
                <img
                  src={coins5}
                  alt="Fill Bag with Coins"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="3"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(4)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">Oppdag mer med EzyGo!</h3>
                <img
                  src={coins2}
                  alt="Discover More"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
              <div
                key="3"
                className="d-flex justify-content-between align-items-center px-4 py-2"
                onClick={() => handleClick(5)}
                style={{ cursor: "pointer" }}
              >
                <h3 className="text-white font-18">
                  Utvid virksomheten din med EzyGo
                </h3>
                <img
                  src={coins1}
                  alt="Discover More"
                  style={{ width: "20%" }}
                  loading="lazy"
                />
              </div>
            </Carousel>
          </div>

          <Row className="px-2 py-2">
            <Col span={12}>
              <Row className="d-flex flex-column">
                <Col span={24} className="image-container">
                  <a
                    onClick={() => {
                      navigate("/salonslist");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={img1}
                      alt="Salong"
                      className="overlay-image"
                      loading="lazy"
                    />
                    <div className="overlay-text">Salong</div>
                  </a>
                </Col>
                <Col span={24} className="image-container">
                  <a
                    onClick={() => {
                      // navigate("/underConstruction");
                      navigate("/digitalServices");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={img3}
                      alt="E-tjeneste"
                      className="overlay-image"
                      loading="lazy"
                    />
                    <div className="overlay-text">E-tjeneste</div>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="d-flex flex-column">
                <Col span={24} className="image-container">
                  <a
                    onClick={() => {
                      dispatch(rootAction.setOrderType("Drive Out"));
                      navigate("/restaurants");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={img2}
                      alt="Bestill mat"
                      className="overlay-image"
                      loading="lazy"
                    />
                    <div className="overlay-text">Bestill mat</div>
                  </a>
                </Col>
                <Col span={24} className="image-container">
                  <a
                    onClick={() => {
                      navigate("/tflMarket");
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={img4}
                      alt="E-marked"
                      className="overlay-image"
                      loading="lazy"
                    />
                    <div className="overlay-text text-white">E-marked</div>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          {advertisements.length > 0 ? (
            <div className="px-3 pb-2 border-radius-10">
              <Carousel
                effect="fade"
                autoplay={true}
                autoplaySpeed={5000}
                ref={carouselRef}
                dots={false}
                className="mt-4 border-radius-10"
              >
                {advertisements.map((data, index) => {
                  return (
                    <div key={index} className=" border-radius-10">
                      {renderAd(data)}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            ""
          )}
          <Row className="px-3 bottomThought pb-5">
            <Col span={24} className="mt-3 sansita-black">
              <p className="pb-5">Oppdag mer med EzyGo!!</p>
            </Col>
          </Row>
        </Container>
      )}
      <BottomFooter />

      <ScrollToTop />
    </Layout>
  );
}

export default App;
