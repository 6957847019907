import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { rootAction } from "../../store/slices/rootSlices";
import {
  Row,
  Col,
  Card,
  Tag,
  Table,
  Descriptions,
  Skeleton,
  Divider,
  Tabs
} from "antd";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";

export default function OrderHistory() {
  let dispatch = useDispatch();
  let location = useLocation();
  let [isLoading, setStateLoading] = useState(true);
  let navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);
  const [show, setShow] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [tflResponseData, setTflResponseData] = useState([]);
  const [tflHistoryCount, setTflHistoryCount] = useState(0);
  const [modalVar, setModalVar] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setModalVar(data);
    // console.log("modalvar", modalVar);
  };

  const columns = [
    {
      title: "Antall",
      dataIndex: "quantity",
      key: "quantity",
      width: "1%"
    },
    {
      title: "Gjenstandsnavn",
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: "Pris",
      dataIndex: "totalPrice",
      key: "totalPrice",
      width: "5%",
      render: (record, item) => {
        return "Kr. " + Number(item.price * item.quantity).toFixed(2);
      }
    }
  ];

  const proColumns = [
    {
      title: "Antall",
      dataIndex: "quantity",
      key: "quantity",
      width: "1%"
    },
    {
      title: "Produktnavn",
      dataIndex: "name",
      key: "name",
      width: "10%"
    },
    {
      title: "Pris",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "5%",
      render: (record, item) => {
        return "Kr. " + Number(item.totalAmount * item.quantity).toFixed(2);
      }
    }
  ];
  useEffect(() => {
    const cardHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };

    const fetchData = async () => {
      setStateLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}${tDriveEndpoint}appuser/appuserallorders`,
          cardHistoryData
        );
        setResponseData(response.data.data.data);
        setHistoryCount(response.data.data.totalOrders);
      } catch (error) {
        console.error(
          "---error user---",
          error.response?.data?.message || error.message
        );
      } finally {
        setStateLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const tflHistoryData = {
      appUserId: user._id,
      delStatus: "all"
    };

    const fetchData = async () => {
      setStateLoading(true);
      try {
        const response = await axios.post(
          `${apiUrl}${tDriveEndpoint}appuser/tfluserallorders`,
          tflHistoryData
        );
        setTflResponseData(response.data.data.data);
        setTflHistoryCount(response.data.data.totalOrders);
      } catch (error) {
        console.error(
          "---error user---",
          error.response?.data?.message || error.message
        );
      } finally {
        setStateLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {isMobile && (
        <Tabs className="orderHistory" defaultActiveKey="item-1">
          <Tabs.TabPane tab="Mathistorie" key="item-1">
            <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
              {isLoading || historyCount === 0 ? (
                <div className="skeltonTopMargin width-100 px-3">
                  <Row>
                    <Col xs={24} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                    <Col xs={24} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                  </Row>
                </div>
              ) : (
                <>
                  {historyCount > 0 ? (
                    <div className="d-flex flex-column width-100">
                      <Row className="mb-5 width-100">
                        {responseData.map((data) => (
                          <Col
                            lg={24}
                            key={data._id}
                            className="width-100 mb-2"
                          >
                            <Card
                              className="historyCard border-radius-10"
                              actions={[
                                <Button
                                  variant="primary"
                                  onClick={() => handleShow(data)}
                                  style={{
                                    width: "100%",
                                    borderRadius: "0 0 10px 10px"
                                  }}
                                >
                                  Bestillingsdetaljer
                                </Button>
                              ]}
                            >
                              <Row
                                id="cardHistory"
                                className="justify-content-between align-items-center"
                              >
                                <Col span={24}>
                                  <h4>
                                    {data?.branchId?.name},&nbsp;
                                    {data?.branchId?.address?.city}
                                  </h4>
                                  <Divider />
                                  <p className="mb-0">
                                    Bestillings Type:{" "}
                                    <span>{data?.orderType}</span>
                                  </p>
                                  <Divider />
                                  <Row className="justify-content-between align-items-center">
                                    <p className="mb-0">
                                      <b>OTP:</b> {data?.otp}
                                    </p>
                                    <Tag color="#f50">{data?.orderStatus}</Tag>
                                  </Row>
                                  <Divider />
                                  <p>
                                    {new Date(data?.createdAt).toLocaleString()}
                                  </p>
                                  {data?.orderStatus === "IN-PROCESS" &&
                                  data?.orderType === "Drive Out" ? (
                                    <>
                                      <p>
                                        Førerens navn:
                                        <span>
                                          {data?.driverId?.firstName}{" "}
                                          {data?.driverId?.lastName}
                                        </span>
                                      </p>
                                      <p>
                                        Sjåførens kontaktnummer:
                                        <span>
                                          {data?.driverId?.phoneNumber}
                                        </span>
                                      </p>
                                    </>
                                  ) : null}
                                  <Divider />
                                  <Row className="justify-content-between align-items-center">
                                    <p className="mb-0">
                                      Totalt:{" "}
                                      <span>Kr. {data?.totalPrice}</span>
                                    </p>
                                    <p className="mb-0">
                                      Quantity:{" "}
                                      <span>{data?.items[0]?.quantity}</span>
                                    </p>
                                  </Row>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                      <p className="text-dark font-18 font-700">
                        Du har ikke bestilt ennå!
                      </p>
                      <a
                        className="aboutNavLink greenAccentBg border-radius-10 font-900"
                        style={{ cursor: "pointer", padding: "8px 50px" }}
                        onClick={() => {
                          dispatch(rootAction.setOrderType("Drive Out"));
                          navigate("/restaurants");
                        }}
                      >
                        BESTILL
                      </a>
                    </div>
                  )}
                </>
              )}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Produkhistorie" key="item-2">
            <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
              {isLoading || tflHistoryCount === 0 ? (
                <div className="skeltonTopMargin width-100 px-3">
                  <Row>
                    <Col xs={24} sm={24} lg={8} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                    <Col xs={24} sm={24} lg={8} className="width-100">
                      <Skeleton avatar paragraph={{ rows: 5 }} />
                    </Col>
                  </Row>
                </div>
              ) : (
                <>
                  {tflHistoryCount > 0 ? (
                    <div className="d-flex flex-column width-100">
                      <Row gutter={[16, 16]} className="mb-5">
                        {tflResponseData.map((data) => (
                          <Col lg={8} key={data._id} className="width-100">
                            <Card className="historyCard width-100 border-radius-10">
                              <Row
                                id="cardHistory"
                                className="justify-content-between align-items-center"
                              >
                                <Col span={24}>
                                  <Row className="justify-content-between align-items-center">
                                    <p className="mb-0">
                                      <b>OTP:</b> {data?.otp}
                                    </p>
                                    <Tag color="#f50">{data?.orderStatus}</Tag>
                                  </Row>
                                  <Divider />
                                  <p className="mb-0">
                                    <b>Kundedetaljer:</b>
                                  </p>
                                  <Table
                                    columns={proColumns}
                                    layout="vertical"
                                    dataSource={data.items}
                                  />
                                  <Divider />
                                  <p>
                                    {new Date(data?.createdAt).toLocaleString()}
                                  </p>
                                  {data?.orderStatus === "IN-PROCESS" &&
                                  data?.orderType === "Drive Out" ? (
                                    <>
                                      <p>
                                        Førerens navn:
                                        <span>
                                          {data?.driverId?.firstName}{" "}
                                          {data?.driverId?.lastName}
                                        </span>
                                      </p>
                                      <p>
                                        Sjåførens kontaktnummer:
                                        <span>
                                          {data?.driverId?.phoneNumber}
                                        </span>
                                      </p>
                                    </>
                                  ) : null}
                                  <Divider />
                                  <Row className="justify-content-between align-items-center">
                                    <p className="mb-0">
                                      Totalt:{" "}
                                      <span>Kr. {data?.totalAmount}</span>
                                    </p>
                                    <p className="mb-0">
                                      Quantity:{" "}
                                      <span>{data?.items[0]?.quantity}</span>
                                    </p>
                                  </Row>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                      <p className="text-dark font-18 font-700">
                        Du har ikke bestilt ennå!
                      </p>
                      <a
                        className="aboutNavLink greenAccentBg border-radius-10 font-900"
                        style={{
                          cursor: "pointer",
                          padding: "8px 50px"
                        }}
                        onClick={() => {
                          navigate("/tflMarket");
                        }}
                      >
                        BESTILL
                      </a>
                    </div>
                  )}
                </>
              )}
            </Row>
          </Tabs.TabPane>
        </Tabs>
      )}
      {!isMobile && (
        <div style={{ marginTop: "60px" }}>
          <Tabs className="orderHistory" defaultActiveKey="item-1">
            <Tabs.TabPane tab="Mathistorie" key="item-1">
              <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
                {isLoading || historyCount === 0 ? (
                  <div className="skeltonTopMargin width-100 px-3">
                    <Row>
                      <Col xs={24} sm={24} lg={8} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                      <Col xs={24} sm={24} lg={8} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    {!historyCount ? (
                      <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                        <p className="text-dark font-18 font-700">
                          Du har ikke bestilt ennå!
                        </p>
                        <a
                          className="aboutNavLink greenAccentBg border-radius-10 font-900"
                          style={{
                            cursor: "pointer",
                            padding: "8px 50px"
                          }}
                          onClick={() => {
                            dispatch(rootAction.setOrderType("Drive Out"));
                            navigate("/restaurants");
                          }}
                        >
                          BESTILL
                        </a>
                      </div>
                    ) : (
                      <div className="d-flex flex-column width-100">
                        <Row gutter={[16, 16]} className="mb-5 px-3">
                          {responseData.map((data) => (
                            <Col lg={8} key={data._id}>
                              <Card
                                className="historyCard border-radius-10"
                                actions={[
                                  <Button
                                    variant="primary"
                                    onClick={() => handleShow(data)}
                                    style={{
                                      width: "100%",
                                      borderRadius: "0 0 10px 10px"
                                    }}
                                  >
                                    Bestillingsdetaljer
                                  </Button>
                                ]}
                              >
                                <Row
                                  id="cardHistory"
                                  className="justify-content-between align-items-center"
                                >
                                  <Col span={24}>
                                    <h4>
                                      {data?.branchId?.name},&nbsp;
                                      {data?.branchId?.address?.city}
                                    </h4>
                                    <Divider />{" "}
                                    <p className="mb-0">
                                      Bestillings Type:{" "}
                                      <span>{data?.orderType}</span>
                                    </p>
                                    <Divider />{" "}
                                    <Row className="justify-content-between align-items-center">
                                      <p className="mb-0">
                                        <b>OTP:</b> {data?.otp}
                                      </p>
                                      <Tag color="#f50">
                                        {data?.orderStatus}
                                      </Tag>
                                    </Row>
                                    <Divider />
                                    <p>
                                      {new Date(
                                        data?.createdAt
                                      ).toLocaleString()}
                                    </p>
                                    {data?.orderStatus === "IN-PROCESS" &&
                                    data?.orderType === "Drive Out" ? (
                                      <>
                                        <p>
                                          Førerens navn:
                                          <span>
                                            {data?.driverId?.firstName}{" "}
                                            {data?.driverId?.lastName}
                                          </span>
                                        </p>
                                        <p>
                                          Sjåførens kontaktnummer:
                                          <span>
                                            {data?.driverId?.phoneNumber}
                                          </span>
                                        </p>
                                      </>
                                    ) : null}
                                    <Divider />
                                    <Row className="justify-content-between align-items-center">
                                      <p className="mb-0">
                                        Totalt:{" "}
                                        <span>Kr. {data?.totalPrice}</span>
                                      </p>
                                      <p className="mb-0">
                                        Quantity:{" "}
                                        <span>{data?.items[0]?.quantity}</span>
                                      </p>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Produkhistorie" key="item-2">
              <Row style={{ marginTop: "64px" }} className="px-3 pt-3">
                {isLoading ? (
                  <div className="skeltonTopMargin width-100 px-3">
                    <Row>
                      <Col xs={24} sm={24} lg={6} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                      <Col xs={24} sm={24} lg={6} className="width-100">
                        <Skeleton avatar paragraph={{ rows: 5 }} />
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    {!tflHistoryCount ? (
                      <div className="d-flex flex-column justify-content-center align-items-center width-100 height-100">
                        <p className="text-dark font-18 font-700">
                          Du har ikke bestilt ennå!
                        </p>
                        <a
                          className="aboutNavLink greenAccentBg border-radius-10 font-900"
                          style={{
                            cursor: "pointer",
                            padding: "8px 50px"
                          }}
                          onClick={() => {
                            navigate("/tflMarket");
                          }}
                        >
                          BESTILL
                        </a>
                      </div>
                    ) : (
                      <div className="d-flex flex-column width-100">
                        <Row gutter={[16, 16]} className="mb-5 px-3">
                          {tflResponseData.map((data) => (
                            <Col lg={6} key={data._id}>
                              <Card className="historyCard border-radius-10">
                                <Row
                                  id="cardHistory"
                                  className="justify-content-between align-items-center"
                                >
                                  <Col span={24}>
                                    <Divider />{" "}
                                    <Row className="justify-content-between align-items-center">
                                      <p className="mb-0">
                                        <b>OTP:</b> {data?.otp}
                                      </p>
                                      <Tag color="#f50">
                                        {data?.orderStatus}
                                      </Tag>
                                    </Row>
                                    <Divider />
                                    <p>
                                      {new Date(
                                        data?.createdAt
                                      ).toLocaleString()}
                                    </p>
                                    {data?.orderStatus === "IN-PROCESS" &&
                                    data?.orderType === "Drive Out" ? (
                                      <>
                                        <p>
                                          Førerens navn:
                                          <span>
                                            {data?.driverId?.firstName}{" "}
                                            {data?.driverId?.lastName}
                                          </span>
                                        </p>
                                        <p>
                                          Sjåførens kontaktnummer:
                                          <span>
                                            {data?.driverId?.phoneNumber}
                                          </span>
                                        </p>
                                      </>
                                    ) : null}
                                    <Divider />
                                    <Row className="justify-content-between align-items-center">
                                      <p className="mb-0">
                                        Totalt:{" "}
                                        <span>Kr. {data?.totalAmount}</span>
                                      </p>
                                      <p className="mb-0">
                                        Quantity:{" "}
                                        <span>{data?.items[0]?.quantity}</span>
                                      </p>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </>
                )}
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
      <Modal show={show} onHide={handleClose} className="modalHistory">
        <Modal.Header closeButton>
          <Modal.Title>
            {modalVar?.branchId?.name} &nbsp;
            <Tag color="#f50">{modalVar?.orderStatus}</Tag>
            <br />
            <i>OTP: {modalVar?.otp}</i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            columns={columns}
            layout="vertical"
            dataSource={modalVar.items}
          />
          <Descriptions bordered layout="vertical">
            {!modalVar?.items?.totalExtraPrice && (
              <Descriptions.Item label="Total Merpris">
                Kr. {modalVar?.items?.totalExtraPrice ?? 0}
              </Descriptions.Item>
            )}
            {modalVar?.tdServiceCharge == 0 ? (
              <></>
            ) : (
              <Descriptions.Item label="Tjenesteskatt">
                Kr. {modalVar?.tdServiceCharge}
              </Descriptions.Item>
            )}
            {modalVar?.discount != 0 && (
              <Descriptions.Item label="Rabatt">
                Kr. {modalVar?.discount}
              </Descriptions.Item>
            )}

            {modalVar?.tdDriveOutCharge == 0 ? (
              <></>
            ) : (
              <Descriptions.Item label="Utkjøringskostnader">
                Kr. {modalVar?.tdDriveOutCharge}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Totalt beløp">
              Kr. {modalVar?.taxPrice ?? 0}
            </Descriptions.Item>
          </Descriptions>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ width: "100%" }}
          >
            Greit
          </Button>
        </Modal.Footer>
      </Modal>
      <BottomFooter />
    </>
  );
}
