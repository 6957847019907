import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Card, Row, Col } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { revertCou } from "../../store/actions/rootaction";
import BottomFooter from "../Footer/BottomFooter";

const SuccessCouponOrderSummary = () => {
  let rootSelector = useSelector((state) => state.rootReducer);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(revertCou());
  }, []);
  return (
    <>
      <div className="formWrapper p-3">
        <Row className="formRow mt-5">
          <Col span={24}>
            <div className="site-card-border-less-wrapper">
              <Card bordered={false} className="border-radius-10">
                {/* <Col className="formHeader">Ordre detaljer</Col> */}
                <div className="formLeft">
                  {rootSelector.orderBYCoupon && (
                    <div>
                      <Row className="justify-content-center">
                        <div className="py-3 d-flex justify-content-center flex-column align-items-center">
                          <AiOutlineCheckCircle
                            style={{
                              width: "150px",
                              height: "150px",
                              color: "#0066CC"
                            }}
                          />
                          <h1 style={{ fontSize: "50px" }}>
                            OTP:{rootSelector.tdcouponPaymentOtp}
                          </h1>
                        </div>
                        <div>
                          <p className="text-center">
                            Takk for bestillingen. Bare for å fortelle deg det
                            Bestillingen din er fullført. Til ordrebekreftelse,
                            vennligst sjekk e-posten din eller meldingen sendt
                            til Mobilnummeret ditt.
                          </p>
                          <br />
                          <p className="text-center">
                            Du må angi OTP på restauranten for å hente.
                            bestilling Vi har også sendt engangsbestillingen til
                            din epostadresse
                          </p>
                        </div>
                        <div className="p-5 goBackBtn">
                          <NavLink to="/home" className="orderBtn">
                            Gå tilbake til startsiden
                          </NavLink>
                        </div>
                      </Row>
                    </div>
                  )}

                  {!rootSelector.orderBYCoupon && (
                    <Row>
                      <Col
                        span={24}
                        style={{ textAlign: "center", padding: "10px" }}
                      >
                        <AiOutlineClose
                          style={{
                            width: "150px",
                            height: "150px",
                            color: "red"
                          }}
                        />
                        <p style={{ fontWeight: "bold" }}>
                          Din bestilling er ikke plassert
                        </p>
                        <br />

                        <Row style={{ marginTop: "20px" }}>
                          <Col span={24}>
                            <NavLink to="/" className="orderBtn">
                              Gå tilbake til startsiden
                            </NavLink>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      <BottomFooter />
    </>
  );
};

export default SuccessCouponOrderSummary;
