import React from "react";
import { Button, Row, Col } from "antd";
import { useNavigate } from "react-router";
import BottomFooter from "../Footer/BottomFooter";

const SalonList = () => {
  let navigate = useNavigate();

  return (
    <>
      <div
        className="salonComingSoon d-flex flex-column justify-content-between"
        style={{ height: "100vh" }}
      >
        <br />
        <h3
          className="font-bold px-3 sansita-black welcome-text"
          style={{ color: "#0066CC " }}
        >
          Få det beste
          <br /> Frisyrer her
        </h3>
        <h2 className="text-white text-center font-bold font-45 comingSoonTextMob">
          Kommer snart
        </h2>
        <Row className="px-3 justify-content-center">
          <Col
            xs={24}
            lg={6}
            className="d-flex justify-content-center flex-column width-100 align-items-center px-3 py-4 mb-5 mt-3 text-center spreadedShadow"
            style={{
              backgroundColor: "#f4c500 "
            }}
          >
            <h6 className="font-16 font-400">
              Oppdag muligheter for å tjene flere mynter.!
            </h6>
            <button
              className="mainBgColor border-radius-50 text-white px-5 py-2 mt-2"
              onClick={() =>
                navigate("/inviteList", {
                  state: { key: "salon" }
                })
              }
              style={{
                cursor: "pointer",
                border: "none",
                transition: "all 0.3s ease"
              }}
            >
              Tjen
            </button>
          </Col>
        </Row>
        {/* <Row className="justify-content-center align-items-center ">
          <Col
            xs={20}
            lg={6}
            className="bg-white m-3 p-3 border-radius-5 text-center"
          >
            {" "}
            <h6 className=" text-center">
              La oss ta med din favorittsalong ombord for den ultimate
              opplevelsen!
            </h6>
            <button
              className="mainBgColor border-radius-50 text-white px-5 py-2 mt-2"
              onClick={() =>
                navigate("/inviteList", {
                  state: { key: "salon" } // Pass the first key
                })
              }
              style={{
                cursor: "pointer",
                border: "none",
                transition: "all 0.3s ease"
              }}
            >
              Inviter
            </button>
          </Col>
        </Row> */}
        <br />
      </div>
      <BottomFooter />
    </>
  );
};

export default SalonList;
