import { useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import offerIcon from "../../../../../assets/mobile/offerTag.webp.webp";
import { s3url } from "../../../../../helper/endpoint";

const ProductCategoryComponent = (props) => {
  useEffect(() => {}, [props.products]);
  return (
    <>
      <ListGroup className="subCatList">
        <h4 className="px-3 py-3 m-0 CatNameDisplay">{props.sinCat}</h4>
        <div className="subCatListUL ">
          {props.products.map((sinProd) => {
            let showOffer = false;

            if (sinProd.offerEnable) {
              let todayDate = new Date();
              let startDate = new Date(sinProd.offer.startDate);
              let endDate = new Date(sinProd.offer.endDate);

              if (todayDate >= startDate && todayDate <= endDate) {
                showOffer = true;
              }
            }

            return (
              <ListGroup.Item
                className="subCategory d-flex justify-content-between align-items-center"
                key={sinProd._id}
                onClick={() => {
                  props.onClickProducts(sinProd);
                }}
              >
                <div className="subCategory d-flex align-items-center">
                  <img
                    alt={sinProd.name}
                    src={`${s3url}/${sinProd.thumbnail}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain", // Ensures the image doesn't stretch and fits inside the box
                    }}
                  />
                  <p className="productDes">{sinProd.name}</p>
                </div>

                {showOffer && (
                  <img
                    src={offerIcon}
                    alt="offer available"
                    className="positionAbsolute"
                    style={{
                      width: "40px",
                      right: 20,
                      top: 0
                    }}
                  />
                )}
              </ListGroup.Item>
            );
          })}
        </div>
      </ListGroup>
    </>
  );
};
export default ProductCategoryComponent;
