import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import { isMobile } from "react-device-detect";
import { Row, Col, Card, message } from "antd";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import axios from "axios";
import { userAction } from "../../store/slices/userslices";
import BottomFooter from "../Footer/BottomFooter";

import { CheckCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";

export default function ChangePass() {
  const navigate = useNavigate();
  let [alertMsg, setAlertMsg] = useState("");
  let dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };
  const updatePassword = async (e) => {
    e.preventDefault();

    let oldPassword = e.target.inputOldPassword.value;
    let newPassword = e.target.inputNewPassword.value;
    let conPassword = e.target.inputConPassword.value;

    let inputFields = [oldPassword, newPassword, conPassword];

    if (!validateForm(inputFields)) {
      // setAlertMsg("Alle felt er obligatoriske.");
      message.error("Alle felt er obligatoriske.");
    } else {
      if (newPassword != conPassword) {
        message.warning("Vennligst bekreft ditt nye passord.");
        return;
      }

      // setAlertMsg("Vennligst vent...");
      message.warning("Vennligst vent...");

      let formData = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        userId: user._id
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/updatepassword`, formData)
        .then((response) => {
          if (response.data.status == 200) {
            message.success("Passordet er oppdatert, vennligst logg på igjen!"); // Now this should work
            // setAlertMsg("Passordet er oppdatert, vennligst logg på igjen!");
            refreshAfterLogout();
            window.location.reload();
          } else {
            message.warning(response.data.message);
            // setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          console.log("---error user---", error.response.data.message);
        });
    }
  };
  const refreshAfterLogout = () => {
    dispatch(userAction.removeUser());

    // Ensure Redux state is updated before navigating
    setTimeout(() => {
      navigate("/login");
    }, 100); // Small delay to allow Redux state update
  };
  const content = () => {
    return (
      <Form noValidate onSubmit={updatePassword}>
        <Form.Group className="row mb-3" controlId="formBasicPassword">
          <Col>
            <FloatingLabel
              controlId="floatingInputGrid"
              label="Gammelt Passord*"
            >
              <Form.Control required type="password" name="inputOldPassword" />
            </FloatingLabel>
          </Col>
        </Form.Group>
        <Form.Group className="row mb-3" controlId="formBasicPassword">
          <Col sm={12}>
            <FloatingLabel controlId="floatingInputGrid" label="Nytt passord*">
              <Form.Control required type="password" name="inputNewPassword" />
            </FloatingLabel>
          </Col>

          <Col sm={12}>
            <FloatingLabel
              controlId="floatingInputGrid"
              label="Bekrefte nytt passord*"
            >
              <Form.Control
                required
                type="password"
                placeholder=""
                name="inputConPassword"
              />
            </FloatingLabel>
          </Col>
        </Form.Group>

        <Row>
          <span style={{ color: "#333" }}>{alertMsg}</span>
        </Row>

        <Row className="justify-content-center mt-2">
          <Button variant="primary" type="submit" className="width-100">
            Oppdater passord
          </Button>
        </Row>
      </Form>
    );
  };
  return (
    <>
      {isMobile ? (
        <div className="vh90andScroll px-3 -auto">
          <Row className="justify-content-between  my-3">
            <h3 class="font-24 font-700 mb-0">Bytt passord</h3>
            <LeftCircleOutlined
              onClick={() => window.history.back()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          </Row>
          <div className="bgColorWhite height py-3 px-1">{content()}</div>
        </div>
      ) : (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="commonShadowAndRound my-5 p-3 bg-white">
              <h2 className="font-20">Bytt passord</h2>
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
