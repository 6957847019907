import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isMobile } from "react-device-detect";
import {
  Card,
  Avatar,
  Typography,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  message
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import { userAction } from "../../store/slices/userslices";
import { s3url, apiUrl, tDriveEndpoint } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";
import profileIcon from "../../assets/profileFallBack.webp";
import moment from "moment"; // Import moment for date formatting

const { Title } = Typography;
const { Option } = Select;

export default function EditProfile() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let [alertMsg, setAlertMsg] = useState("");
  const [formData, setFormData] = useState({});
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const validateForm = (valid_inputs) => {
    for (let i = 0; i < valid_inputs.length; i++) {
      if (valid_inputs[i] == "") {
        return false;
      }
    }
    return true;
  };

  let user = useSelector((state) => state.userReducer);
  let userprofileImage = user.profileImage;

  useEffect(() => {
    console.log("Checking user token:", user.token);
    if (!user || !user.token) {
      console.log("Redirecting to login...");
      navigate("/login");
    }
  }, [user]);

  const profileImageSrc =
    userprofileImage == "" ? profileIcon : `${s3url}/${userprofileImage}`;
  const handleSubmit = async (e) => {
    e.preventDefault();
    let firstName = e.target.inputFirstName.value;
    let lastName = e.target.inputLastName.value;
    let token = user.token;

    let inputFields = [firstName, lastName, token];

    if (!validateForm(inputFields)) {
      // console.log(firstName, lastName, token);
    } else {
      // setAlertMsg("Vennligst vent...");

      message.warning("Vennligst vent...");
      const formattedDob = dob ? moment(dob).format("YYYY-MM-DD") : null;
      let formData = {
        firstName: firstName,
        lastName: lastName,
        gender: gender, // Include gender
        dateOfBirth: formattedDob // Include formatted date of birth
      };

      await axios
        .post(`${apiUrl}${tDriveEndpoint}appuser/updateuser`, formData, {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token
          }
        })
        .then((response) => {
          // console.log("---response server---", response);

          if (response.data.status == 200) {
            // setAlertMsg("Profile updated successfully.");
            message.success("Profilen ble oppdatert."); // Now this should work
            let updateData = {
              firstName: response.data.data.firstName,
              lastName: response.data.data.lastName,
              email: user.email,
              phone: user.phone,
              countryCode: user.countryCode,
              id: user._id,
              refferalCode: user.userReferralCode,
              token: user.token
            };

            dispatch(userAction.saveUser(updateData));
          } else {
            
            message.warning(response.data.message);
            // setAlertMsg(response.data.message);
          }
        })
        .catch((error) => {
          // console.log("---error user---", error.response.data.message);
        });
    }
  };

  const content = () => {
    return (
      <Card className="border-radius-10">
        <Row className="justify-content-between">
          <h3 class="font-24 font-700 mb-0">Rediger profil</h3>
          <LeftCircleOutlined
            onClick={() => window.history.back()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "30px"
            }}
          />
        </Row>
        <Row
          className="justify-content-between align-items-center pb-5
      "
        >
          <Col
            span={24}
            className="mt-2 d-flex flex-column align-items-center justify-content-center"
          >
            {" "}
            <Avatar
              size={100}
              src={profileImageSrc}
              style={{ marginBottom: "10px" }}
              alt={`${user.firstName}`}
            />
            <p className="font-18">{`${user.firstName} ${user.lastName}`}</p>
          </Col>{" "}
          <Form noValidate onSubmit={handleSubmit} className="width-100 mb-5">
            <Form.Group className="row  mb-3 mt-2" controlId="inputFirstName">
              <Col sm={24} className="mb-3">
                <FloatingLabel controlId="floatingInputGrid" label="Fornavn*">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Fornavn"
                    name="inputFirstName"
                    defaultValue={user.firstName || ""}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        firstName: e.target.value
                      }));
                    }}
                  />
                </FloatingLabel>
              </Col>

              <Col sm={24}>
                <FloatingLabel controlId="floatingInputGrid" label="Etternavn*">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Etternavn"
                    name="inputLastName"
                    defaultValue={user.lastName}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        lastName: e.target.value
                      }));
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>

            <Form.Group className="row mb-3" controlId="formBasicEmail">
              <Col sm={24} className="mb-3">
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Mobilnummer*"
                >
                  <Form.Control
                    disabled={true}
                    required
                    type="number"
                    placeholder="Mobilnummer"
                    name="inputPhone"
                    defaultValue={user.phone}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={24}>
                <FloatingLabel controlId="floatingInputGrid" label="E-post*">
                  <Form.Control
                    disabled={true}
                    required
                    type="email"
                    placeholder="name@example.com"
                    name="inputEmail"
                    defaultValue={user.email}
                  />
                </FloatingLabel>
              </Col>
            </Form.Group>
            <Form.Group className="row mb-3 justify-content-between">
              <Col sm={6} lg={6}>
                <FloatingLabel controlId="dob" label="Fødselsdato*" />
              </Col>
              <Col sm={6} lg={15}>
                <DatePicker
                  className="width-100"
                  placeholder="Velg fødselsdato"
                  value={
                    dob
                      ? moment(dob)
                      : user.dateOfBirth
                      ? moment(user.dateOfBirth)
                      : null
                  }
                  onChange={(date) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      dateOfBirth: date ? date.format("YYYY-MM-DD") : "" // Ensure previous state persists
                    }))
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group className="row mb-3 justify-content-between">
              <Col sm={6} lg={6}>
                <FloatingLabel controlId="gender" label="Kjønn*" />
              </Col>

              <Col sm={6} lg={15}>
                <Select
                  style={{ width: "100%" }}
                  value={formData.gender || user.gender || ""} // Ensure state controls the value
                  onChange={(value) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      gender: value // Update gender while keeping previous state
                    }))
                  }
                >
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Col>
            </Form.Group>

            <Row>
              <span style={{ color: "#333" }}>{alertMsg}</span>
            </Row>

            <Row className="justify-content-center mt-2">
              <Button variant="primary" type="submit" className="width-100">
                Oppdater profil
              </Button>
            </Row>
          </Form>
        </Row>
      </Card>
    );
  };

  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll">{content()}</Row>
      ) : (
        <div style={{ marginTop: "70px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col span={8} className="commonShadowAndRound my-5">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
