import { Button } from "antd";

const Footer = (props) => {
  return (
    <>
      {!props.isEnablePay && (
        <Button
          type="primary"
          className="saveBtn w-100 mt-4"
          disabled={!props.formValid}
          onClick={props.onAddCustomer}
        >
          Lagre og fortsett
        </Button>
      )}
    </>
  );
};

export default Footer;
