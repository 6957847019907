import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Badge, Modal, Alert } from "antd";
import { FileTextOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import axios from "axios";
import { s3url, apiUrl } from "../../helper/endpoint";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import tflCoin from "../../assets/mobile/tflCoins.png";
import { NavLink } from "react-router-dom";
import BottomFooter from "../Footer/BottomFooter";
import { couponAction } from "../../store/slices/couponslices";
const TflMarket = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [marketData, setMarketData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(couponAction.resetCouponState()); // Dispatch the action to reset coupon state
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/treeDrive/appuser/getAllTFLProducts`
        );
        setMarketData(response.data.data);
        localStorage.setItem("pointsValue", response.data.pointsValue);
        setDeliveryCharges(response.data.deliveryCharges);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };
    fetchData();
  }, []);

  const totalAmount = useMemo(() => {
    return cartData.reduce((acc, item) => acc + item.amount * item.quantity, 0);
  }, [cartData]);

  const updateCart = (productInfo, productAction, id) => {
    setCartData((prevCartData) => {
      let totalLength = 0; // Initialize total length
      let totalBreadth = 0; // Initialize total breadth
      let totalHeight = 0; // Initialize total height

      const existingItemIndex = prevCartData.findIndex(
        (item) => item._id === (productInfo ? productInfo._id : id)
      );

      const updatedCart = prevCartData
        .map((item, index) => {
          if (index === existingItemIndex) {
            let newQuantity = item.quantity;
            if (productAction === "add") {
              if (item.quantity < item.availableQuantity) {
                newQuantity += 1;
              } else {
                alert(`Only ${item.availableQuantity} available in stocks.`);
                newQuantity = item.availableQuantity;
              }
            } else if (productAction === "remove") {
              newQuantity -= 1;
            }

            if (newQuantity <= 0) {
              return null;
            }

            totalLength += item.dimensions.length * item.quantity; // Add item length to total length
            totalBreadth += item.dimensions.breadth * item.quantity; // Add item length to total breadth
            totalHeight += item.dimensions.height * item.quantity; // Add item length to total height

            return {
              ...item,
              quantity: newQuantity,
              totalAmount: item.amount * newQuantity,
              weight: item.weight * newQuantity,
              length: item.dimensions.length,
              breadth: item.dimensions.breadth,
              height: item.dimensions.height
            };
          }

          return item;
        })
        .filter((item) => item !== null);

      // If the item is new and being added to the cart
      if (existingItemIndex === -1 && productInfo) {
        const newItem = {
          ...productInfo,
          totalAmount: productInfo.amount,
          quantity: 1,
          weight: productInfo.weight,
          length: productInfo.dimensions.length,
          breadth: productInfo.dimensions.breadth,
          height: productInfo.dimensions.height
        };

        return [...updatedCart, newItem];
      }
      return updatedCart;
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.setItem("TFLCartData", JSON.stringify(cartData));
    const totalLength = cartData.reduce(
      (acc, item) => acc + item.dimensions.length * item.quantity,
      0
    );
    const totalBreadth = cartData.reduce(
      (acc, item) => acc + item.dimensions.breadth * item.quantity,
      0
    );
    const totalHeight = cartData.reduce(
      (acc, item) => acc + item.dimensions.height * item.quantity,
      0
    );
    navigate("/tflMarketCart", {
      state: {
        cartData,
        deliveryCharges,
        totalAmount,
        totalWeight: cartData.reduce((acc, item) => acc + item.weight, 0),
        totalLength,
        totalBreadth,
        totalHeight
      }
    });
  };

  useEffect(() => {
    const count = cartData.reduce((acc, item) => acc + item.quantity, 0);

    setCartItemCount(count);

    // console.log("cartItemCount", count);
  }, [cartData]);
  const clearCart = () => {
    setCartData([]);
    localStorage.removeItem("TFLCartData");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleExpand = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const truncate = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    localStorage.removeItem("appliedCouponCode");
    const backCartData = localStorage.getItem("TFLCartData");
    if (backCartData) {
      setCartData(JSON.parse(backCartData));
    }
  }, []);
  return (
    <>
      {isMobile && (
        <>
          {" "}
          {marketData != 0 ? (
            <>
              <Row>
                <Col
                  span={24}
                  className="px-4 position-fixed w-100 d-flex justify-content-end align-items-center"
                  style={{ zIndex: 999, top: 0, height: "64px" }}
                >
                  <Badge
                    count={cartItemCount}
                    onClick={showModal}
                    className="text-white"
                  >
                    Handlevogn &nbsp;
                    <ShoppingCartOutlined />
                  </Badge>
                </Col>
              </Row>
              <Row className="mt-3 mb-5 py-5" id="tflMarketRow">
                <Col span={24} className="p-3 ">
                  {marketData.map((data) => {
                    const isExpanded = expanded[data._id];
                    return (
                      <Card
                        key={data._id}
                        className="mb-3 eMarketCard border-radius-5"
                        // actions={[]}
                      >
                        <Row className="justify-content-between ">
                          <Col
                            span={9}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <img
                              loading="lazy"
                              className="width-100"
                              alt="Product"
                              src={`${s3url}/${data.url}`}
                            />
                          </Col>
                          <Col span={14} className="p-2">
                            <h5 className="font-18">
                              <b>{data.productName}</b>
                            </h5>
                            <p>
                              {/* <b>Beskrivelse: &nbsp;</b> */}
                              <FileTextOutlined className="font-18" /> &nbsp;
                              {isExpanded
                                ? data.description
                                : truncate(data.description, 28)}
                              <Button
                                type="link"
                                onClick={() => toggleExpand(data._id)}
                                style={{ height: "auto" }}
                                className="truncateClass p-0"
                              >
                                {isExpanded ? "Gjemme" : "Vise"}
                              </Button>
                            </p>

                            <div className="d-flex justify-content-between mb-1">
                              <p className="mb-0">
                                <img src={tflCoin} />
                                &nbsp;
                                {/* <b>Beløp</b>: */}
                                {data.amount} Kr.
                              </p>
                              {/* <p className="mb-0">
                                <b>Coins</b>: {data.RequiredPts}{" "}
                                <img src={smallCoin} alt="Coin" />
                              </p> */}
                            </div>
                            {data.availableQuantity > 0 ? (
                              user.token ? (
                                <Button
                                  className="w-100 border-0 mainBgColor text-white border-radius-50 font-12 font-700"
                                  onClick={() => updateCart(data, "add")}
                                  style={{
                                    backgroundColor: "#1890ff"
                                  }}
                                >
                                  Legge til +
                                </Button>
                              ) : (
                                <NavLink
                                  to="/login"
                                  className="w-100 py-1 d-flex justify-content-center align-items-center"
                                  style={{
                                    textDecoration: "none",
                                    backgroundColor: "#ddd", // Light gray background to indicate disabled state
                                    color: "#999", // Muted text color
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    fontWeight: "600",
                                    cursor: "not-allowed",
                                    opacity: 0.6 // Slightly transparent to indicate it's disabled
                                  }}
                                >
                                  Logg inn for å legge til +
                                </NavLink>
                              )
                            ) : (
                              <Button
                                disabled
                                className="w-100 border-0"
                                style={{
                                  backgroundColor: "#f0f0f0", // Disabled button style
                                  color: "#aaa", // Muted text color
                                  fontWeight: "600",
                                  padding: "10px 20px",
                                  fontSize: "12px",
                                  borderRadius: "5px"
                                }}
                              >
                                Solgt
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                </Col>
              </Row>
            </>
          ) : (
            <Row
              className="my-5 py-5 d-flex justify-content-center align-items-center h-100"
              id="tflMarketRow"
            ></Row>
          )}
        </>
      )}
      {!isMobile && (
        <>
          {" "}
          {marketData != 0 ? (
            <>
              <Row>
                <Col
                  span={24}
                  className="px-4 position-fixed d-flex justify-content-end align-items-center"
                  style={{ zIndex: 999, top: 0, height: "64px", right: 0 }}
                >
                  <Badge
                    count={cartItemCount}
                    onClick={showModal}
                    className="text-white"
                  >
                    Handlevogn &nbsp;
                    <ShoppingCartOutlined />
                  </Badge>
                </Col>
              </Row>
              <Row
                className="mt-3 mb-5 py-5"
                id="tflMarketRow"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {marketData.map((data) => {
                  const isExpanded = expanded[data._id];
                  return (
                    <Col xs={8} md={8} xl={6} className="p-3 d-flex">
                      <Card
                        key={data._id}
                        className="mb-3 eMarketCard border-radius-5 width-100"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          height: "100%"
                        }}
                      >
                        <Row className="justify-content-between height-100">
                          <Col
                            span={9}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <img
                              loading="lazy"
                              className="width-100"
                              alt="Product"
                              src={`${s3url}/${data.url}`}
                            />
                          </Col>
                          <Col
                            span={14}
                            className="p-2 d-flex flex-column justify-content-between height-100"
                          >
                            <div>
                              {" "}
                              <h5 className="font-18">
                                <b>{data.productName}</b>
                              </h5>
                              <p>
                                <FileTextOutlined className="font-18" /> &nbsp;
                                {isExpanded
                                  ? data.description
                                  : truncate(data.description, 28)}
                                <Button
                                  type="link"
                                  onClick={() => toggleExpand(data._id)}
                                  style={{ height: "auto" }}
                                  className="truncateClass p-0"
                                >
                                  {isExpanded ? "Gjemme" : "Vise"}
                                </Button>
                              </p>
                              <div className="d-flex justify-content-between mb-1">
                                <p className="mb-0">
                                  <img src={tflCoin} />
                                  &nbsp;{data.amount} Kr.
                                </p>
                              </div>
                            </div>
                            {data.availableQuantity > 0 ? (
                              user.token ? (
                                <button
                                  className="w-100 border-0 mainBgColor text-white border-radius-50 font-16 font-700 py-2"
                                  onClick={() => updateCart(data, "add")}
                                  style={{ backgroundColor: "#1890ff" }}
                                >
                                  Legge til +
                                </button>
                              ) : (
                                <NavLink
                                  to="/login"
                                  className="w-100 py-1 d-flex justify-content-center align-items-center"
                                  style={{
                                    textDecoration: "none",
                                    backgroundColor: "#ddd",
                                    color: "#999",
                                    padding: "10px 20px",
                                    borderRadius: "5px",
                                    fontWeight: "600",
                                    cursor: "not-allowed",
                                    opacity: 0.6
                                  }}
                                >
                                  Legg til
                                </NavLink>
                              )
                            ) : (
                              <Button
                                disabled
                                className="w-100 border-0"
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  color: "#aaa",
                                  fontWeight: "600",
                                  padding: "10px 20px",
                                  fontSize: "12px",
                                  borderRadius: "5px"
                                }}
                              >
                                Solgt
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
              ;
            </>
          ) : (
            <Row
              className="my-5 py-5 d-flex justify-content-center align-items-center h-100"
              id="tflMarketRow"
            ></Row>
          )}
        </>
      )}
      <BottomFooter />
      <Modal
        title="Handlevogn"
        visible={isModalOpen}
        onCancel={handleCancel}
        className="tflCartModal "
        style={{ zIndex: 9999999, height: "80vh", overflowY: "scroll" }}
        footer={[
          <>
            {cartItemCount >= 1 && (
              <>
                {" "}
                {cartItemCount >= 1 ? (
                  <Col span={24} className="text-left px-2">
                    <a onClick={clearCart} style={{ color: "red" }}>
                      <u>Clear Cart</u>
                    </a>
                  </Col>
                ) : (
                  ""
                )}
                <Col span={24} className="p-2">
                  <div className="d-flex justify-content-between">
                    <p>Total</p>
                    {totalAmount} Kr
                  </div>
                </Col>
                <Button
                  key="ok"
                  type="primary"
                  onClick={handleOk}
                  className="w-100 saveBtn border-radius-50"
                >
                  Fortsette
                </Button>
              </>
            )}
          </>
        ]}
      >
        {cartItemCount < 1 ? (
          <div className="d-flex justify-content-between mb-3">
            <p className="mb-0 d-flex align-items-center">
              Handlekurven din har totalt &nbsp;<b>{cartItemCount}&nbsp;</b>{" "}
              varer.
            </p>
          </div>
        ) : (
          ""
        )}
        {totalAmount != 0 && (
          <div className="tflCartHeight">
            {cartData.map((item) => (
              <>
                {" "}
                <Row
                  key={item._id}
                  className="justify-content-between border p-2 mb-2 border-radius-5"
                >
                  <Col
                    span={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img
                      src={`${s3url}/${item.url}`}
                      style={{ width: "100%" }}
                      alt="Product"
                    />
                  </Col>
                  <Col span={17}>
                    <div className="d-flex justify-content-between">
                      <p>
                        <strong>{item.productName}</strong>
                        {/* &nbsp;*{item.quantity} */}
                      </p>

                      <p className="mb-2">
                        <b>{item.amount * item.quantity} Kr. </b>
                      </p>
                    </div>

                    <div className="increBtn tflCart py-1 justify-content-start">
                      <span
                        className="minus"
                        onClick={() => updateCart(null, "remove", item._id)}
                      >
                        -
                      </span>
                      <span className="px-3">{item.quantity}</span>
                      {item.availableQuantity === item.quantity ? (
                        <span className="plus disabledBtn">+</span>
                      ) : (
                        <span
                          className="plus"
                          onClick={() => updateCart(null, "add", item._id)}
                        >
                          +
                        </span>
                      )}
                    </div>

                    <Alert
                      className="alertStyle"
                      type="warning"
                      showIcon
                      message={
                        <p className="mb-0">
                          Skynd deg {item.availableQuantity} igjen!
                        </p>
                      }
                    />
                  </Col>
                </Row>
              </>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
};

export default TflMarket;
