import { Layout, Tag } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LeftCircleOutlined } from "@ant-design/icons";
import { FaShoppingCart } from "react-icons/fa";
import logo from "../../assets/landscapelogo.webp";
import maplocationImg from "../../assets/mobile/maplocationImg.webp";
import ConfirmOrderModal from "../../containers/Modal/ConfirmModal";
import { rootAction } from "../../store/slices/rootSlices";
import { isMobile } from "react-device-detect";
import {
  revertCart,
  revertDis,
  revertLocation,
  revertProduct,
  revertRestaurant,
  revertRoot,
  revertCou
} from "../../store/actions/rootaction";

const { Header, Content } = Layout;
const HeaderLayout = (props) => {
  const navigate = useNavigate();
  let address = useSelector((state) => state.locationReducer);
  let cartLength = useSelector((state) => state.cartReducer.cartItems.length);
  let cartData = useSelector((state) => state.cartReducer);
  console.log("cartData", cartData);
  let [locationPath, setLocationPath] = useState("");
  let [prevLocation, setPrevLocation] = useState("");
  let dispatch = useDispatch();
  let [showConfirmModal, setShowConfirmModal] = useState(false);
  let location = useLocation();
  let [isRestaurant, setisRestaurant] = useState(false);
  let root = useSelector((state) => state.rootReducer);
  let user = useSelector((state) => state.userReducer);
  const resName = localStorage.getItem("resName");
  let [ifSlugIsThere, setIfSlugIsThere] = useState();
  const restaurant = useSelector((state) => state.restaurantReducer.restaurant);
  let res = useSelector((state) => state.restaurantReducer.selectedRestaurant);
  const uniqueItems = new Map();

  cartData.cartItems.forEach((item) => {
    const instructionKey = item.instruction
      ? JSON.stringify(item.instruction)
      : "no-instruction";
    const key = `${item._id}-${instructionKey}`;

    if (!uniqueItems.has(key)) {
      uniqueItems.set(key, item);
    }
  });

  const cartCount = uniqueItems.size; // Unique product count based on id & instruction

  useEffect(() => {
    setIfSlugIsThere(localStorage.getItem("resName"));
    console.log("ifSlugIsThere", ifSlugIsThere);
  }, []);
  const getRestaurantNameCity = () => {
    let resNameCity = "";
    if (res && Object.keys(res).length > 0) {
      resNameCity = res.name + ", " + res.address.city;
    }
    return resNameCity;
  };
  const ifTreeDriveOn = () => {
    return !location.pathname.includes("/restaurant/") ? (
      <>
        {ifSlugIsThere && location.pathname == "/checkout" ? (
          ""
        ) : (
          <img
            src={logo}
            alt="logo"
            className="logoSize border-radius-5"
            height={"60px"}
          />
        )}
      </>
    ) : (
      <></>
    );
  };

  useEffect(() => {
    setLocationPath(location.pathname);

    let restaurant = location.pathname.split("/");
    restaurant = restaurant[1] == "restaurant" ? true : false;
    setisRestaurant(restaurant);
    if (location.pathname == "/home") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertLocation());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertRoot());
    }
    if (location.pathname == "/restaurants") {
      dispatch(revertCart());
      dispatch(revertDis());
      dispatch(revertProduct());
      dispatch(revertRestaurant());
      dispatch(revertCou());
    }

    if (window?.innerWidth > 720) {
      setPrevLocation("/home");
    } else if (
      location.pathname == "/profile" ||
      location.pathname == "/login" ||
      location.pathname == "/signup"
    ) {
      setPrevLocation("/home");
    } else {
      setPrevLocation("/restaurants");
    }
  }, [location.pathname]);

  const onConfirmOrderModal = () => {
    setShowConfirmModal(true);
  };
  const onCancelConfrimModal = () => {
    setShowConfirmModal(false);
  };

  let getHeaderlayout = () => {
    let selOptions = [];
    let isSelected = "";

    if (res && Object.keys(res).length > 0) {
      if (
        res.isTreeDriveDriveOutAllowed == true &&
        res.isRestDriveOut == true
      ) {
        isSelected = root.orderType == "Drive Out" ? "selected" : "";
        selOptions.push({
          optValue: "Drive Out",
          label: "Utkjøring",
          isSelected
        });
      }

      if (res.isTreeDrivePickUpAllowed == true && res.isRestPickup == true) {
        isSelected = root.orderType == "Take Away" ? "selected" : "";
        selOptions.push({
          optValue: "Take Away",
          label: "Henting",
          isSelected
        });
      }
    }
    let layout = "";
    if (
      locationPath == "/restaurants" ||
      locationPath == "/reserveTable" ||
      locationPath == "/retailerList" ||
      locationPath == "/inviteList" ||
      locationPath == "/tflMarketCart"
    ) {
      layout = (
        <div className="search-location width-100 d-flex">
          <a className="top-bar locBtn" onClick={props.onChangeLocationHandler}>
            <span className="locSpan text-white d-flex align-items-center">
              Velg Sted: &nbsp;
              {isMobile ? (
                <u>{`${address.locationAddress.substr(0, 20)}...`} &nbsp;</u>
              ) : (
                <u>{`${address.locationAddress.substr(0, 28)}...`} &nbsp;</u>
              )}
              &nbsp;
              <img alt="maplocationImg" src={maplocationImg} />
            </span>
          </a>
          {locationPath == "/tflMarketCart" && isMobile && (
            <LeftCircleOutlined
              onClick={() => window.history.back()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px"
              }}
            />
          )}
        </div>
      );
    } else if (locationPath == "/products" || isRestaurant) {
      layout = (
        <>
          {res?.availability?.status == null ? <></> : <></>}
          {res?.availability?.status === true ? (
            <div className="d-flex justify-content-end width-100">
              {res?.isTreeDriveDriveOutAllowed === "true" &&
              res?.isTreeDrivePickUpAllowed === "true" ? (
                <select
                  className="dropSelect"
                  onChange={(e) => {
                    dispatch(rootAction.setOrderType(e.target.value));
                  }}
                >
                  <option
                    value="Take Away"
                    selected={root.orderType == "Take Away" ? "selected" : ""}
                  >
                    Henting
                  </option>
                  <option
                    value="Drive Out"
                    selected={root.orderType == "Drive Out" ? "selected" : ""}
                  >
                    Levering
                  </option>
                </select>
              ) : (
                <p className="rootOrderType mb-0">
                  <select
                    className="dropSelect border-radius-50 text-black"
                    onChange={(e) => {
                      dispatch(rootAction.setOrderType(e.target.value));
                    }}
                  >
                    {selOptions.map((e) => {
                      return (
                        <option value={e.optValue} selected={e.isSelected}>
                          {e.label}
                        </option>
                      );
                    })}
                  </select>
                </p>
              )}
              <div
                className="cartLogo positionRelative"
                onClick={onConfirmOrderModal}
              >
                <FaShoppingCart className="cartIcon text-white" />

                <Badge bg="secondary" className="cartBadge">
                  {cartLength ? cartCount : ""}
                </Badge>
              </div>
            </div>
          ) : (
            <Tag color="#f50">Stengt</Tag>
          )}
        </>
      );
    }

    return layout;
  };

  let getCityAndName = () => {
    let layout1 = "";
    if (locationPath == "/restaurants") {
      layout1 = <div></div>;
    }

    if (
      locationPath == "/products" ||
      locationPath == "/checkout" ||
      isRestaurant
    ) {
      layout1 = (
        <div className="d-flex width-100">
          <h3 className="mb-0 text-white d-flex align-items-center width-100">
            &nbsp;&nbsp;{getRestaurantNameCity()}
          </h3>
          {locationPath == "/checkout" ? (
            <LeftCircleOutlined
              onClick={() => window.history.back()}
              className="border-radius-100"
              style={{
                backgroundColor: "#FDDC2E",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "40px"
              }}
            />
          ) : (
            <></>
          )}
        </div>
      );
    }
    return layout1;
  };
  return (
    <Header className="other-nav mainBgColor width-100 positionFixed zIndex-99 d-flex align-items-center justify-content-between">
      <div className="navWrapper d-flex width-100">
        {!isMobile && (
          <div className="d-flex align-items-center width-100">
            <NavLink to="/home" className="nav-logo">
              {ifTreeDriveOn()}
            </NavLink>
            {getCityAndName()}
          </div>
        )}
        {isMobile && (
          <>
            {window.location.pathname == "/checkout" ? (
              <>
                {!ifSlugIsThere ? (
                  <>
                    <NavLink
                      to={
                        window.location.pathname == "/checkout"
                          ? "/products"
                          : prevLocation
                      }
                      className="nav-logo col-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                      >
                        <path d="M16.25 22.6 5.625 11.975 16.25 1.35l2.2 2.2-8.425 8.425L18.45 20.4Z" />
                      </svg>
                    </NavLink>
                  </>
                ) : (
                  <LeftCircleOutlined
                    onClick={() => window.history.back()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "30px"
                    }}
                  />
                )}

                {resName}
              </>
            ) : (
              <>
                {window.location.pathname == "/terms" ||
                window.location.pathname == "/privacy" ||
                window.location.pathname == "/agentProfile" ? (
                  <LeftCircleOutlined
                    onClick={() => window.history.back()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "30px"
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            )}
            {window.location.pathname == "/products" ? (
              <h3 className="textWhite font-22 mb-0 font-700">{resName}</h3>
            ) : (
              <></>
            )}
          </>
        )}

        {getHeaderlayout()}
      </div>
      {showConfirmModal && cartLength > 0 && (
        <ConfirmOrderModal
          onCancelConfrimModal={onCancelConfrimModal}
        ></ConfirmOrderModal>
      )}
    </Header>
  );
};
export default HeaderLayout;
