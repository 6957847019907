import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col, Layout, Carousel, Card } from "antd";
import giftCard1 from "../../assets/mobile/giftCard1.webp";
import giftCard2 from "../../assets/mobile/giftCard2.webp";
import blueArrow from "../../assets/mobile/SWIPE.webp";
import agentkort from "../../assets/Doll Web/Agentkort.webp";
import BottomFooter from "../Footer/BottomFooter";
import { isMobile } from "react-device-detect";

export default function TFLGiftCardPage() {
  let user = useSelector((state) => state.userReducer);
  const [isExpanded, setIsExpanded] = useState(false);
  let navigate = useNavigate();
  const { Meta } = Card;
  const content = () => {
    return (
      <>
        <Row className=" px-3 py-4">
          <Col span={24}>
            <h3 class="font-24 font-700">Agentkort</h3>
          </Col>
          <Col span={24} className="">
            <div>
              <p
                className={`mb-0 ${isExpanded ? "" : "truncate"}`}
                style={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: isExpanded ? "none" : 2
                }}
              >
                Opplev personlig bekvemmelighet med agentkortet, laget for å
                matche dine ønsker. Få tilgang til det globalt, når som helst og
                hvor som helst. Nyt dette kortet som tilbyr 10–90 % merverdi,
                som kan brukes på tvers av nettverket vårt og partnerbedrifter.
                Konverter ubrukte saldoer til mynter eller doner til globale
                initiativer, og forsikre deg om at hver krone teller mot en
                bedre verden!
              </p>
              <button
                onClick={toggleReadMore}
                style={{
                  background: "none",
                  border: "none",
                  color: "#007BFF", // Link color
                  cursor: "pointer",
                  padding: 0,
                  marginTop: "5px"
                }}
              >
                {isExpanded ? "Les mindre" : "Les mer"}
              </button>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-around align-items-center giftCardBg px-3 py-4 mx-2 border-radius-10">
          <Col span={24} className="text-center mt-2 mb-1">
            <p className="font-18 font-700">Forhånds-Agent-kort</p>
          </Col>
          <Col span={11} className="text-center">
            {" "}
            <Card
              className="border-radius-5"
              hoverable
              onClick={() => {
                navigate("/getTflCouponforme");
              }}
              cover={
                <img
                  alt="Gift Card"
                  src={giftCard2}
                  height={"100%"}
                  className="border-radius-5"
                />
              }
            >
              <p>
                Sikre deg selv
                <br /> et agent kort nå!
              </p>
            </Card>
          </Col>
          <Col span={11} className="text-center">
            {" "}
            <Card
              className="border-radius-5"
              hoverable
              onClick={() => {
                navigate("/getTflCouponforothers");
              }}
              cover={
                <img
                  alt="Gift Card"
                  src={giftCard1}
                  height={"100%"}
                  className="border-radius-5"
                />
              }
            >
              <p>
                Gi et agentkort <br /> til noen spesiell!
              </p>
            </Card>
          </Col>
        </Row>
        <Row className="p-2  mb-3 width-100">
          <Col
            span={24}
            className="mb-1 d-flex align-items-center justify-content-center"
          >
            <NavLink
              to="/showTflGiftCard"
              className="width-100 position-relative"
              style={{
                display: "block"
              }}
            >
              <img
                src={blueArrow}
                alt="Blue Arrow"
                className="border-radius-10 width-100"
                style={{
                  height: "auto"
                }}
              />
              <span
                className="position-absolute text-white font-900 font-18"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                Se agentkort
              </span>
            </NavLink>
          </Col>
        </Row>
        <Row className="px-3 bottomThought pt-4 pb-5">
          <Col span={24} className="mt-3 sansita-black">
            <p>
              Noen gaver er store, andre små, men de som gis fra hjertet er de
              aller største.
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      {isMobile && <Row className="vh90andScroll">{content()}</Row>}
      {!isMobile && (
        <div style={{ marginTop: "64px" }} className="bannerImg">
          <Row className="align-items-center justify-content-between px-5 agentWeb">
            <Col span={14}>
              <h3 class="font-55 font-700 sansita-black mb-1">Agentkort</h3>
              <div>
                <p
                  className={`mb-0 ${isExpanded ? "" : "truncate"}`}
                  style={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: isExpanded ? "none" : 2
                  }}
                >
                  Opplev personlig bekvemmelighet med agentkortet, laget for å
                  matche dine ønsker. Få tilgang til det globalt, når som helst
                  og hvor som helst. Nyt dette kortet som tilbyr 10–90 %
                  merverdi, som kan brukes på tvers av nettverket vårt og
                  partnerbedrifter. Konverter ubrukte saldoer til mynter eller
                  doner til globale initiativer, og forsikre deg om at hver
                  krone teller mot en bedre verden!
                </p>
                <button
                  onClick={toggleReadMore}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#007BFF", // Link color
                    cursor: "pointer",
                    padding: 0,
                    marginTop: "5px"
                  }}
                >
                  {isExpanded ? "Les mindre" : "Les mer"}
                </button>
              </div>
              <br />
              <p
                className="font-24 font-700 sansita-black"
                style={{ color: "#0066CC" }}
              >
                Forhånds-Agent-kort
              </p>
              <Row gutter={[16, 16]} className="tflGiftPAge">
                <Col md={12} xl={8}>
                  <Card
                    className="border-radius-10 text-center"
                    hoverable
                    onClick={() => {
                      navigate("/getTflCouponforme");
                    }}
                  >
                    {" "}
                    <img
                      alt="Gift Card"
                      src={giftCard2}
                      height={"100"}
                      className="border-radius-5"
                    />
                    <p className="mb-0" style={{ color: "#0066CC" }}>
                      Sikre deg selv <br />
                      et agent kort nå!
                    </p>
                  </Card>
                </Col>
                <Col md={12} xl={8}>
                  {" "}
                  <Card
                    className="border-radius-10  text-center"
                    hoverable
                    onClick={() => {
                      navigate("/getTflCouponforothers");
                    }}
                  >
                    <img
                      alt="Gift Card"
                      src={giftCard1}
                      height={"100"}
                      className="border-radius-5"
                    />
                    <p className="mb-0" style={{ color: "#0066CC" }}>
                      Gi et agentkort <br /> til noen spesiell!
                    </p>
                  </Card>
                </Col>
              </Row>
              <br />
              <div>
                {" "}
                <NavLink to="/showTflGiftCard" className=" position-relative">
                  <div
                    className=" text-white font-900 font-18 d-flex justify-content-center align-items-center"
                    style={{
                      display: "block",
                      backgroundImage: `url(${blueArrow})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      padding: "10px",
                      width: "300px"
                    }}
                  >
                    Se agentkort
                  </div>
                </NavLink>
              </div>
              <p className="sansita-black font-24" style={{ color: "#0066CC" }}>
                Noen gaver er store, andre små, men de som gis fra hjertet er de
                aller største.
              </p>
            </Col>
            <Col span={9}>
              <img src={agentkort} alt="agentKort" width={"100%"} />
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
