import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router";
import { useEffect } from "react";
import { sendLocationAction } from "./store/actions/locationaction";
import "./App.css";
// routes
import EzygoIndex from "./components/Ezygo/EzygoIndex";
import OnlineOrderNew from "./components/OnlineOrderPage/OnlineOrderNew";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Privacy/Terms";
import Cancellation from "./components/Privacy/Cancellation";
import ScamAlert from "./components/Privacy/ScamAlert";
import Disclaimer from "./components/Privacy/Disclaimer";
import TopBar from "./components/Header/TopBar";
import ReserveTable from "./components/Restaurants/ReserveTable";
import RestaurantListComponent from "./components/Restaurants/ResturantListComponent";
import DriveOutCheckout from "./components/Checkout/DriveoutCheckout";
import { OrderSummary } from "./components/RestaurantProducts/OrderSummary";
import { Product } from "./components/RestaurantProducts/Products/Product";
import DigitalServices from "./components/WebServices/DigitalServices";
import SalonsList from "./components/Salons/SalonList";
import TflMarket from "./components/TFLMarket/TflMarket";
import TflMarketCart from "./components/TFLMarket/TflMarketCart";
import EmpTy from "./components/EmptyPage/EmpTy";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import Reset from "./components/Reset/Reset";
import Verify from "./components/Verify/Verify";
import OrderHistory from "./components/Profile/OrderHistory";
import TflGiftCardOthers from "./components/TFL Gift Card/TflGiftCardOthers";
import TflGiftCardMyself from "./components/TFL Gift Card/TflGiftCardMyself";
import TFLOrderSummary from "./components/TFLMarket/TFLOrderSummary";
import TFLCoinExchange from "./components/TFL Gift Card/TFLCoinExchange";
import ShowTflGiftCard from "./components/TFL Gift Card/ShowTFLGiftCard";
import TFLGiftCardPage from "./components/TFL Gift Card/TFLGiftCardPage";
import SuceessCouponPurchase from "./components/Coupon/BuyCoupon/SuceessCouponPurchase";
import QueryForm from "./components/ContactForm/GeneralForm";
import SuccessCouponOrderSummary from "./components/RestaurantProducts/SuccessCoupon";
import ShareReferral from "./components/Profile/ShareReferral";
import Modal1Slide from "./components/OnlineOrderPage/Modal1Slide";
import ShortCutToAll from "./components/Profile/ShortCutToAll";
import ABoard from "./components/ABoard/ABoard";
import ProfileInABoard from "./components/ABoard/ProfileInABoard";
import EditProfile from "./components/ABoard/EditProfile";
import ChangePass from "./components/ABoard/ChangePass";
import ToInviteList from "./components/ToInvitePages/ToInviteList";
import AgentProfile from "./components/ABoard/AgentProfile";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";
import Contact from "./components/OnlineOrderPage/Contact";
import EzygoPrivacy from "./components/AppPrivacy/EzygoPrivacy";
import EzygoTerms from "./components/AppPrivacy/EzygoTerms";
import { isMobile } from "react-device-detect";

function App() {
  let dispatch = useDispatch();
  // if ("serviceWorker" in navigator) {
  //   navigator.serviceWorker.getRegistrations().then((registrations) => {
  //     registrations.forEach((reg) => reg.unregister()); // Unregister old SW
  //   });
  //   caches.keys().then((cacheNames) => {
  //     cacheNames.forEach((cacheName) => caches.delete(cacheName)); // Delete caches
  //   });
  //   window.location.reload(); // Reload the page to fetch new assets
  // }
  // 🔒 Security Enhancements
  // useEffect(() => {
  //   dispatch(sendLocationAction());

  //   // ⛔ Disable right-click (context menu)
  //   const handleContextMenu = (e) => e.preventDefault();

  //   // ⛔ Disable DevTools (F12, Ctrl+Shift+I)
  //   const handleKeyDown = (e) => {
  //     if (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && e.keyCode === 73)) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [dispatch]);

  // // 🛑 Remove console logs in production
  // if (process.env.NODE_ENV === "production") {
  //   console.log = () => {};
  //   console.warn = () => {};
  //   console.error = () => {};
  // }

  return (
    <Routes>
      <Route path="/" element={<EzygoIndex />}>
        <Route path="/home" element={<OnlineOrderNew />} />
        <Route path="/tflGiftCard" element={<TFLGiftCardPage />} />
        <Route path="/showTflGiftCard" element={<ShowTflGiftCard />} />
        <Route path="/getTflCouponforothers" element={<TflGiftCardOthers />} />
        <Route path="/getTflCouponforme" element={<TflGiftCardMyself />} />
        <Route path="/shortCutToAll" element={<ShortCutToAll />} />
        <Route path="/a-board" element={<ABoard />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/changePassword" element={<ChangePass />} />
        <Route path="/tflCoinExchange" element={<TFLCoinExchange />} />
        <Route path="/modal/:modalId" element={<Modal1Slide />} />
        <Route path="/salonsList" element={<SalonsList />} />
        <Route path="/underConstruction" element={<UnderConstruction />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/orderstatuscoupon"
          element={<SuccessCouponOrderSummary />}
        />
        {!isMobile && <Route path="/orderHistory" element={<OrderHistory />} />}
        {!isMobile && (
          <Route path="/digitalServices" element={<DigitalServices />} />
        )}

        <Route path="/queryForm" element={<QueryForm />} />
        <Route path="/shareReferral" element={<ShareReferral />} />
        {!isMobile && <Route path="/agentProfile" element={<AgentProfile />} />}
      </Route>

      <Route path="" element={<TopBar />}>
        {isMobile && <Route path="/agentProfile" element={<AgentProfile />} />}
        {isMobile && <Route path="/orderHistory" element={<OrderHistory />} />}
        {isMobile && (
          <Route path="/digitalServices" element={<DigitalServices />} />
        )}
        <Route path="/reserveTable" element={<ReserveTable />} />
        <Route path="/restaurants" element={<RestaurantListComponent />} />
        <Route path="/inviteList" element={<ToInviteList />} />
        <Route path="/tflMarketcart" element={<TflMarketCart />} />
        <Route path="/profileInAboard" element={<ProfileInABoard />} />
        <Route path="/tflMarket" element={<TflMarket />} />
        <Route path="/TFLOrderSummary" element={<TFLOrderSummary />} />
        <Route path="/RestaurantList/ordersummary" element={<OrderSummary />} />

        <Route exact path="/restaurant/:slug" element={<Product />} />
        <Route path="/products" element={<Product />} />
        <Route path="/checkout" element={<DriveOutCheckout />} />
        <Route path="/orderstatus" element={<OrderSummary />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Route>

      <Route path="/salesAndCancellation" element={<Cancellation />} />
      <Route path="/scamAlert" element={<ScamAlert />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/login" element={<Login />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/ezygoprivacy" element={<EzygoPrivacy />} />
      <Route path="/ezygoterms" element={<EzygoTerms />} />
      <Route path="/couponpaymentstatus" element={<SuceessCouponPurchase />} />
      <Route path="/EmptyPage" element={<EmpTy />} />
    </Routes>
  );
}

export default App;
