import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Cards from "../Cards/Cards";
import { Row, Col, Carousel, Card, Button } from "antd";
import { s3url } from "../../helper/endpoint";
import {
  getRestaurants,
  sendLocationAction
} from "../../store/actions/locationaction";
import { LeftCircleOutlined } from "@ant-design/icons";
import smallIconBtn from "../../assets/mobile/Utkjøring_Henting.webp";
import greenStar from "../../assets/mobile/greenstar.webp";
import EmptyComponent from "../EmptyPage/EmpTy";
import { isMobile } from "react-device-detect";
import { socket } from "../../helper/socket";
import { restaurantAction } from "../../store/slices/restaurantslices";
import ScrollToTop from "../../components/ScrollToTop";
import { rootAction } from "../../store/slices/rootSlices";
import BottomFooter from "../Footer/BottomFooter";
const RestaurantListComponent = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const restaurant = useSelector((state) => state.restaurantReducer.restaurant);
  const user = useSelector((state) => state.userReducer);
  const location = useSelector((state) => state.locationReducer);
  let orderType = useSelector((state) => state.rootReducer.orderType);
  const [isLoading, setIsLoading] = useState(true);
  const [tdDriveOutDelay, setTdDriveOutDelay] = useState(null);
  const [tdTakeAwayDelay, setTdTakeAwayDelay] = useState(null);

  const carouselRef = useRef(null);

  useEffect(() => {
    socket.on("treeDriveAvailSer", async (data) => {
      setIsLoading(true);
      dispatch(restaurantAction.removeRestaurants());
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(sendLocationAction());
  }, [dispatch]);

  useEffect(() => {
    // Extract delays from restaurant data
    if (restaurant && restaurant.length > 0) {
      const firstRestaurant = restaurant[0];
      setTdDriveOutDelay(firstRestaurant.tdDriveOutDelay);
      setTdTakeAwayDelay(firstRestaurant.tdTakeAwayDelay);
    }
  }, [restaurant]);

  useEffect(() => {
    dispatch(
      getRestaurants(
        {
          lat: location.latitude,
          lng: location.longitude
        },
        orderType,
        tdDriveOutDelay,
        tdTakeAwayDelay
      )
    )
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [dispatch, location, orderType, tdDriveOutDelay, tdTakeAwayDelay]);

  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    let ads = [];
    restaurant &&
      restaurant.map((res) => {
        let resAds = res.branchAds;
        if (resAds && resAds.length > 0) {
          let vids = resAds.filter((e) => e.type === "video");
          let images = resAds.filter((e) => e.type === "picture");
          let gifs = resAds.filter((e) => e.type === "gif");
          let html = resAds.filter((e) => e.type === "html");

          images[0]?.data.forEach((d) => ads.push(d));
          gifs[0]?.data.forEach((d) => ads.push(d));
          vids[0]?.data.forEach((d) => ads.push(d));
          html[0]?.data.forEach((d) => ads.push(d));
        }
      });

    setAdvertisements(ads);
  }, [restaurant]);

  const handleVideoEnd = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const renderAd = (data) => {
    const adType =
      data.contentType === "video"
        ? "video"
        : data.contentType === "html"
        ? "html"
        : "picture";
    let url = `${s3url}/${data.url}`;

    let ele = <img src={url} alt={adType} loading="lazy" height={"220px"} />;

    if (adType === "video") {
      ele = (
        <video controls width="100%" height="220px" onEnded={handleVideoEnd}>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (adType === "html") {
      const startDate = new Date(
        data?.timePeriod?.startDate
      ).toLocaleDateString();
      const endDate = new Date(data?.timePeriod?.endDate).toLocaleDateString();
      return (
        <Card
          className="border-radius-10 p-1"
          style={{
            height: "100px"
          }}
        >
          <Row className="justify-content-between">
            <Col span={4}>
              <img
                alt={data.productName}
                src={`${s3url}/${data.url}`}
                className="border-radius-100"
                width={"100%"}
              />
            </Col>
            <Col span={19}>
              <div className="d-flex justify-content-between">
                <h4 className="mb-0">{data.productName}</h4>
                <p className="mb-0">{data.branchName}</p>
              </div>
              <div className="d-flex">
                <img alt="green star" src={greenStar} />
                <p className="mb-0">
                  Kr. {data.prices.offerPrice}/-{" "}
                  <strike>Kr. {data.prices.originalPrice}/-</strike>
                </p>
              </div>
              <p className="mb-0">
                Offer Date: {startDate} to {endDate}
              </p>
            </Col>
          </Row>
        </Card>
      );
    }

    return ele;
  };

  return (
    <>
      {isMobile && (
        <Row className="pt-4 mt-5">
          {advertisements.length > 0 ? (
            <Carousel
              effect="fade"
              autoplay={true}
              autoplaySpeed={5000}
              ref={carouselRef}
              dots={false}
              className="px-2 "
            >
              {advertisements.map((data, index) => {
                return (
                  <div
                    className="width-100"
                    key={index}
                    // onClick={() => console.log("--clicked on--", data._id)}
                  >
                    {renderAd(data)}
                  </div>
                );
              })}
            </Carousel>
          ) : (
            ""
          )}
        </Row>
      )}
      {isMobile ? (
        <>
          <div className=" px-3">
            <p className="mb-2">Utforsk også</p>
          </div>
          <div className=" px-3">
            <Button
              className="border-radius-5 width-100 font-700 py-0 px-5 text-white font-16"
              style={{ height: "70px", background: "#f7a143" }}
              onClick={() => {
                dispatch(rootAction.setOrderType("Drive Out"));
                navigate("/restaurants");
              }}
            >
              <img src={smallIconBtn} width="50px" />
              &nbsp;&nbsp; Utkjøring/ Henting
            </Button>
          </div>
        </>
      ) : (
        <>
          <Row
            className="justify-content-between px-5"
            style={{ marginTop: "70px" }}
          >
            <Col span={24}>
              {" "}
              <p className="mb-2">Utforsk også</p>
            </Col>
            <Col>
              <div>
                <Button
                  className="border-radius-5 text-white font-700 py-0 px-5 text-white font-16"
                  style={{ height: "70px", background: "#f7a143" }}
                  onClick={() => {
                    dispatch(rootAction.setOrderType("reserveTable"));
                    navigate("/restaurants");
                    window.location.reload(); // Refresh the page
                  }}
                >
                  <img src={smallIconBtn} width="50px" />
                  &nbsp;&nbsp; Utkjøring/ Henting
                </Button>
              </div>
            </Col>
            <Col>
              <LeftCircleOutlined
                onClick={() => navigate("/")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "40px"
                }}
              />
            </Col>
          </Row>
        </>
      )}
      <Cards restaurant={restaurant} isLoading={isLoading} />
      {restaurant && restaurant.length === 0 && !isLoading && (
        <EmptyComponent />
      )}
      <BottomFooter />
      <ScrollToTop />
    </>
  );
};
export default RestaurantListComponent;
