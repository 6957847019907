import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import friends from "../../assets/friends.webp";
import BottomFooter from "../Footer/BottomFooter";
import { ezyGoWebLinkPoint } from "../../helper/endpoint";

export default function ShareReferral() {
  let navigate = useNavigate();
  let user = useSelector((state) => state.userReducer);
  const userReferralCode = user.userReferralCode || "*******";

  console.log("user", user);

  const handleShare = async () => {
    const referralUrl = `${ezyGoWebLinkPoint}/signup?ref=${userReferralCode}`;
    const message = `Jeg bruker EzyGo! Smarteste løsningen for mat, salong og produktbestilling i Norge. 
    Få eksklusive tilbud, belønninger og en smidig bestillingsordning. 
    Bruk min henvisningskode ${userReferralCode} når du registrerer deg og tjen TFL-mynter. 
 Registrer deg direkte her: Last ned nå
    
    <a href="https://apps.apple.com/in/app/ai-ezygo/id6444897318" target="_blank">iOS</a> | 
    <a href="https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser&hl=en_IN" target="_blank">Android</a>`;

    try {
      // Copy to clipboard
      await navigator.clipboard.writeText(message);
      console.log("Henvisningskode kopiert til utklippstavlen!");

      if (navigator.share) {
        // Native share API for mobile
        await navigator.share({
          title: "Henvisningskode",
          text: message,
          url: referralUrl
        });
      } else {
        // Fallback: Open Gmail (mailto)
        const subject = encodeURIComponent(
          "Bli med i EzyGo med min henvisningskode!"
        );
        const body = encodeURIComponent(message);
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.open(mailtoLink, "_blank");
      }
    } catch (error) {
      console.error("Feil under deling av henvisningskode:", error);
    }
  };

  useEffect(() => {
    if (!user.token) {
      navigate("/login");
    }
  }, [user, navigate]);

  const content = () => (
    <Row className="p-3 justify-content-center">
      <Col span={24}>
        <h3 className="font-24 font-700 mt-3" style={{ color: "#0066CC " }}>
          Inviter venner til EzyGo!
        </h3>
        <p>
          Inviter vennene dine til EzyGo og få mynter kreditert etter å ha lagt
          inn sin første bestilling. Og la dem tjene mynter på SignUp.
        </p>
      </Col>
      <Col
        sm={24}
        lg={8}
        className="d-flex align-items-center justify-content-center"
      >
        <img src={friends} loading="lazy" alt="Invite Friends" width={"100%"} />
      </Col>
      <Col span={24}>
        <Row
          style={{ background: "#0066CC" }}
          className="border-radius-10 p-3 text-white justify-content-between align-items-center"
        >
          <Col>
            <h3 className="font-24 text-white mb-1">{userReferralCode}</h3>
            <p className="mb-0">Din henvisningskode er</p>
          </Col>
          <Col>
            <Button
              type="text"
              shape="circle"
              icon={
                <ShareAltOutlined style={{ color: "#fff", fontSize: "60px" }} />
              }
              onClick={handleShare}
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} className="mt-3">
        <p>
          <b>
            <i>Jo mer du henviser, jo mer tjener du!</i>
          </b>
        </p>
        <p>
          Du vet at du elsker oss, det vil vennene dine også gjøre.
          <br /> Del det og del moroa.
        </p>
      </Col>
    </Row>
  );

  return (
    <>
      {isMobile ? (
        <Row className="vh90andScroll">{content()}</Row>
      ) : (
        <div style={{ marginTop: "64px" }} className="mission profilePages">
          <Row className="align-items-center justify-content-center">
            <Col lg={12} className="commonShadowAndRound my-5 bg-white">
              {content()}
            </Col>
          </Row>
        </div>
      )}
      <BottomFooter />
    </>
  );
}
