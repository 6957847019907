import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Descriptions, Table } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { apiUrl } from "../../helper/endpoint";
import BottomFooter from "../Footer/BottomFooter";

export default function TFLOrderSummary() {
  let paymentType = "Dintero";
  const paramUrl = window.location.href; // Get the current URL
  const encUrl = new URL(paramUrl); // Parse the URL
  const params = new URLSearchParams(encUrl.search); // Extract the search/query string

  // Extract `orderId` from the query parameters
  const orderId = params.get("orderId");
  console.log("OrderId from URL:", orderId); // Log the extracted orderId

  let merchant_reference = params.get("merchant_reference");
  let transaction_id = params.get("transaction_id");
  let [stateLoading, setStateLoading] = useState(true);
  let [paymentFailed, setPaymentFailed] = useState(false);
  let [resData, setResData] = useState("");
  let [productsData, setProductsData] = useState([]);

  const checkVendorPayment = async (data) => {
    try {
      let res = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/orderPaymentCheckTfl`,
        data
      );
      if (res && res.status == 200) {
        setResData(res.data.data);
        setProductsData(res.data.data.items);
        setPaymentFailed(false);
        // console.log("res", res.data.data.items);
      } else {
        // console.log("error");
        setPaymentFailed(true);
      }
    } catch (error) {
      // console.log("error in ctach");
      setPaymentFailed(true);
    }
  };
  const checkCouponSuccessPayment = async () => {
    try {
      let res = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedrivepayment/orderPaymentCheckTfl`
      );
      if (res && res.status == 200) {
        setPaymentFailed(false);
      } else {
        // console.log("error");
        setPaymentFailed(true);
      }
    } catch (error) {
      setPaymentFailed(true);
    }
  };

  const deleteDataFromLocal = () => {
    localStorage.setItem("TFLCartData", []);
  };

  useEffect(() => {
    let data = {
      id: merchant_reference,
      transaction_id
    };
    checkVendorPayment(data);
  }, []);

  const OrderColumns = () => [
    {
      title: "Product",
      width: "37%",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "Total Quantity",
      width: "37%",
      key: "quantity",
      render: (text, record) => {
        return record.availQuantity
          ? `${record.quantity}pkt X ${record.availQuantity}${record.unitType}`
          : `${record.quantity}`;
      }
    },
    {
      title: `Amount(Kr.) `,
      width: "26%",
      key: "name",
      render: (text, record) => {
        return `Kr.${record.totalAmount}`;
      }
    }
  ];
  return (
    <>
      {" "}
      <div className="formWrapper pt-5" id="vendoStatus">
        <Col span={12}>
          <Card bordered={false} className="cardContainer">
            {!paymentFailed && (
              <Row className="justify-content-start p-3 flex-column">
                <div className="py-3 d-flex justify-content-start align-items-center">
                  {" "}
                  <AiOutlineCheckCircle
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#0066CC",
                      marginRight: "10px"
                    }}
                  />
                  <h4 className="text-white mb-0">Bestillingen ble lagt inn</h4>
                </div>
                <div>
                  <p>
                    <b>Din OTP: {resData.otp}</b>.
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    {/* <i>
                      Vi er glade for å bekrefte din nylige matbestilling med
                      Tree Drive. Du vil motta ordrebekreftelsen via e-post og
                      SMS som inneholder OTP og angi denne OTP ved mottak. IKKE
                      del denne engangskoden med noen andre.
                    </i> */}
                  </p>
                  <>
                    <Descriptions
                      title="Bestillingsinformasjon"
                      className="mt-3"
                    ></Descriptions>{" "}
                    <Table
                      id="tablePrint"
                      columns={OrderColumns()}
                      dataSource={productsData}
                      striped
                      bordered
                      hover
                    ></Table>
                    <Descriptions className="pt-5">
                      <Descriptions.Item label="Total">
                        <b> Kr. {resData.totalAmount}</b>
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                  <Descriptions title="User Info" className="mt-3">
                    <Descriptions.Item label="Address">
                      {resData?.customerDetail?.address}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="py-3 goBackBtn">
                  <NavLink to="/" className="orderBtn">
                    Utforsk flere produkter
                  </NavLink>
                </div>
              </Row>
            )}

            {paymentFailed && (
              // !stateLoading &&
              <Row className="justify-content-start p-3 flex-column">
                <div className="py-3 d-flex justify-content-start flex-column align-items-center">
                  {" "}
                  <AiOutlineClose
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "red",
                      marginRight: "10px"
                    }}
                  />
                  <h4 className=" mb-0">Å nei, bestillingen mislyktes</h4>
                </div>
                <div></div>
                <div>
                  <p style={{ fontSize: "14px" }}>
                    {/* <i>
                      Vi beklager å måtte informere deg om at det var et problem
                      med din nylig matbestilling gjennom EzyGo
                      Platform/Restaurant Navn. Vi beklager eventuelle ulemper
                      dette kan medføre forårsaket.
                    </i> */}
                  </p>
                </div>
                <div className="py-3 goBackBtn">
                  <NavLink
                    to="/tflMarket"
                    className="orderBtn"
                    onClick={deleteDataFromLocal}
                  >
                    Utforsk flere produkter
                  </NavLink>
                  <NavLink to="/tflMarketCart" className="orderBtn">
                    Tilbake til betalingssiden
                  </NavLink>
                </div>
              </Row>
            )}
          </Card>
        </Col>
      </div>
      {/* <BottomFooter /> */}
    </>
  );
}
